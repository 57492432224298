import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {Dialog} from "@headlessui/react";
import {triggerUserRegister} from "../../../Store/Action/session.login";
import {convertApiMessageToNotificationMessage, NotificationMessage} from "../../App/Common/NotificationMessage";

const ComponentTranslationKey = "Login"

type ModalRegisterState = {
    messages: INotificationMessage[]
}

export default function ModalRegister(props: {
    isModalOpen: boolean
    onModalClose: () => void
    onBackToLogin: () => void
}) {

    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    const [state, setState] = useState<ModalRegisterState>({
        messages: []
    })

    const propsExtend = useSelector((state: IAppState) => ({
        isModalOpen: props.isModalOpen,
        onModalClose: props.onModalClose,
    }))

    const onRegisterError = function (e: IApiResponseError | IApiResponseMessage) {
        const message = convertApiMessageToNotificationMessage(e, 'msg-' + state.messages.length)
        setState({
            ...state,
            messages: [...state.messages, message]
        })
    }

    const onRegisterSubmit = function (e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            name: { value: string };
            surname: { value: string };
            email: { value: string };
            email2: { value: string };
            password: { value: string };
        };

        const name = target.name.value;
        const surname = target.surname.value;
        const email = target.email.value;           // do typecheck?
        // const email2 = target.email2.value;             // input disabled
        const email2 = email;
        const password = target.password.value;

        dispatch(triggerUserRegister(name, surname, email, email2, password, onRegisterError));
    }

    return <Dialog as="div" className="relative z-50"
                   open={props.isModalOpen}
                   onClose={props.onModalClose}>
        <div className="modal-shade-overlay"/>
        <div className="modal-wrapper-space">
            <div className="modal-wrapper-vertical-align text-center">

                <Dialog.Panel className="modal-login-wrapper px-24 pb-6 pt-32 relative">

                    <div className={`absolute top-4 right-4 cursor-pointer`}
                         onClick={propsExtend.onModalClose}>
                        <img src={`/resources/close-icon.png`} alt={`Close`}/>
                    </div>

                    <Dialog.Title as="h2">
                        {getTranslation('new_user')}
                    </Dialog.Title>

                    <div className="text-base mt-8">
                        <form onSubmit={onRegisterSubmit}>
                            <div>
                                <div className="text-sm">{getTranslation('first_name')}</div>
                                <input type="text" name="name"
                                       className="p-1 mt-1 w-full border focus:ring-shopit-green focus:border-shopit-green focus:ring-1 focus:outline-none"/>
                            </div>
                            <div className="mt-2">
                                <div className="flex justify-between">
                                    <div className="text-sm">{getTranslation('last_name')}</div>
                                </div>
                                <input type="text" name="surname"
                                       className="p-1 mt-1 w-full border focus:ring-shopit-green focus:border-shopit-green focus:ring-1 focus:outline-none"/>
                            </div>

                            <div className="mt-8">
                                <div className="flex justify-between">
                                    <div className="text-sm">{getTranslation('email')}</div>
                                </div>
                                <input type="email" name="email"
                                       className="p-1 mt-1 w-full border focus:ring-shopit-green focus:border-shopit-green focus:ring-1 focus:outline-none"/>
                            </div>
                            {/*<div className="mt-2">*/}
                            {/*    <div className="flex justify-between">*/}
                            {/*        <div className="text-sm">Repeat email</div>*/}
                            {/*    </div>*/}
                            {/*    <input type="email" name="email2"*/}
                            {/*           className="p-1 mt-1 w-full border focus:ring-shopit-green focus:border-shopit-green focus:ring-1 focus:outline-none"/>*/}
                            {/*</div>*/}
                            <div className="mt-2">
                                <div className="flex justify-between">
                                    <div className="text-sm">{getTranslation('password')}</div>
                                </div>
                                <input type="password" name="password" style={{width: "50%"}}
                                       className="p-1 mt-1 w-full border focus:ring-shopit-green focus:border-shopit-green focus:ring-1 focus:outline-none"/>
                            </div>

                            <div className="mt-4 relative">
                                <button
                                    className="text-sm block btn btn-green btn-bold btn-uppercase py-2 px-2 rounded cursor-pointer text-center w-full"
                                    type="submit">
                                    {getTranslation('create_new_user')}
                                </button>

                                <div className={`absolute -top-[30px] -right-[300px] z-50`}>
                                    {state.messages?.map((message, index) =>
                                        <NotificationMessage message={message} key={`k${index}`}/>)}
                                </div>
                            </div>

                        </form>
                    </div>

                </Dialog.Panel>
            </div>
        </div>
    </Dialog>
}
