import React, {MouseEventHandler, ReactElement, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDomainImageUrl} from "../../../Helpers/ImageHelper";
import {Link} from "react-router-dom";
import {Price} from "../Price";
import {ProductImage} from "../../App/Common/Image";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {getDebugProductUrl} from "../../../Helpers/BackendHelper";
import {replaceSpecialUnicodeCharacters} from "../../../Helpers/StringFormatter.Text";


/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "Product.Tab.ProductOffers"

/** @see ProductVendorsDemo */
export default function ProductOffersTable(props: { product: IProduct }) {

    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet["Product"]["Tab"]["ProductOffers"]) {
        // i18next have problem with process '.' separated path to key as default
        // so we force a path with ':'
        // i tried to debug, but not understand how it works... but works
        // (copy comment for copy+paste multilevel translations functions)
        return t('Product:Tab:ProductOffers:' + key)
    }

    const [state, setState] = useState({unfoldUsedInstances: false})

    const isDebugEnabled = useSelector((state: IAppState) => state.config.debug)

    const imageOnErrorHandler = function (e: React.SyntheticEvent) {
        (e.currentTarget as any).src = getDomainImageUrl(undefined, '');
    }

    const onToggleUsedInstances: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        setState({unfoldUsedInstances: !state.unfoldUsedInstances})
    }

    const renderList = function (productOffers: IProductOffer[]) {

        let activeCount = 0
        for (const productOffed of productOffers) {
            if (productOffed.priceInclVat && productOffed.productUrl && productOffed.productUrl.length > 0) {
                activeCount++
            }
        }
        let bottom: ReactElement | null = null
        if (activeCount < productOffers.length) {
            bottom = <div className={`vendors-instance bottom-row`} key={`b`}>{
                getTranslation('stats')
                    .replace("<total_offers_count>", '' + productOffers.length)
                    .replace("<active_offers_count>", '' + activeCount)
            }</div>
        }

        return <div>
            <div className={`vendors-instance`} key={`h`}>
                <div
                    className="vendors-instance-image-column header-row hidden sm:block">{getTranslation('product')}</div>
                <div className="vendors-instance-details-wrapper">
                    <div
                        className="vendors-instance-domain-column header-row hidden sm:block">{getTranslation('store')}</div>
                    <div
                        className="vendors-instance-status-column header-row hidden sm:block">{getTranslation('availability')}</div>
                    <div
                        className="vendors-instance-price-column header-row hidden sm:block">{getTranslation('price')}</div>
                    <div
                        className="vendors-instance-buttons-column header-row hidden sm:block">{getTranslation('link')}</div>
                </div>
            </div>

            {productOffers.map((productOffer, index) => {
                const hasUrl = productOffer.productUrl && productOffer.productUrl.length > 0
                let shippingTime = null;
                if (productOffer.shipping) {
                    if (productOffer.shipping.shippingDaysMin !== undefined || productOffer.shipping.shippingDaysMax) {
                        if (productOffer.shipping.shippingDaysMin !== undefined && productOffer.shipping.shippingDaysMax) {
                            shippingTime = productOffer.shipping.shippingDaysMin + "-" + productOffer.shipping.shippingDaysMax + " " + getTranslation('days')
                        } else if (productOffer.shipping.shippingDaysMin !== undefined) {
                            shippingTime = getTranslation('from') + " " + productOffer.shipping.shippingDaysMin
                            if (productOffer.shipping.shippingDaysMin === 1) {
                                shippingTime += " " + getTranslation('day')
                            } else {
                                shippingTime += " " + getTranslation('days')
                            }
                        } else {
                            shippingTime = getTranslation('up_too') + " " + productOffer.shipping.shippingDaysMax
                            if (productOffer.shipping.shippingDaysMax === 1) {
                                shippingTime += " " + getTranslation('day')
                            } else {
                                shippingTime += " " + getTranslation('days')
                            }
                        }
                        shippingTime = <div>{shippingTime}</div>
                    }
                }
                return <div className={`vendors-instance${hasUrl ? '' : ' no-url'}`}
                            key={'index' + index}
                            title={hasUrl ? '' : getTranslation('not_paid')}>
                    <div className="vendors-instance-image-column">
                        <div className="vendors-instance-image-wrapper">
                            <ProductImage imageId={productOffer.productMainImageId || 0} alt={productOffer.productTitle}
                                          size={'xs'}/>
                        </div>
                    </div>
                    <div className="vendors-instance-details-wrapper">
                        <div className="vendors-instance-domain-column">
                            <div className="vendors-instance-domain-wrapper">
                                <div className="vendors-instance-domain-instance-column">
                                    <span className={`font-bold`}>{productOffer.websiteStore.name}</span>
                                    {productOffer.marketplaceMerchant
                                        ? <span className={`text-gray-400`}>
                                        {' / '}{productOffer.marketplaceMerchant.name}
                                    </span>
                                        : null}
                                    {isDebugEnabled && productOffer.marketplaceMerchant
                                        ? <span
                                            className="text-gray-500">{' '}(merchant_id={productOffer.marketplaceMerchant.id})</span>
                                        : null}
                                    {isDebugEnabled
                                        ? <div>
                                            {productOffer.websiteStore.websiteUrl}
                                        </div>
                                        : null}
                                    <div className="text-gray-700">{isDebugEnabled
                                        ? productOffer.productTitle
                                        : replaceSpecialUnicodeCharacters(productOffer.productTitle)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="vendors-instance-status-column">
                            {productOffer.statusFound !== 'FOUND' ? getTranslation("discontinued") :
                                productOffer.stockStatus === 'IN_STOCK' ? getTranslation("in_stock") :
                                    productOffer.stockStatus === 'OUT_OF_STOCK' ? getTranslation("out_of_stock") :
                                        productOffer.stockStatus === 'BACKORDER' ? getTranslation("backorder") :
                                            productOffer.stockStatus === 'PREORDER' ? getTranslation("pre_order") :
                                                getTranslation("stock_unknown")
                            }
                            {isDebugEnabled && productOffer.stockQuantity && productOffer.stockQuantity > 0
                                ? (' (' + productOffer.stockQuantity + ')') : null}
                            {shippingTime}
                            {isDebugEnabled && productOffer.condition
                                ? <div>{productOffer.condition}</div>
                                : null}
                        </div>
                        <div className="vendors-instance-price-column">
                            {productOffer.priceSaleInclVat ? <div className={`font-bold`}>
                                <Price price={productOffer.priceSaleInclVat}
                                       currency={productOffer.currency}
                                       unbuyable={false}/>
                            </div> : null}
                            {(!productOffer.priceSaleInclVat || isDebugEnabled) && productOffer.priceInclVat
                                ? <div className={!productOffer.priceSaleInclVat ? `font-bold` : `line-through`}>
                                    <Price price={productOffer.priceInclVat}
                                           currency={productOffer.currency}
                                           unbuyable={false}/>
                                </div> : null}
                        </div>
                        <div className="vendors-instance-buttons-column">

                            {hasUrl
                                && <Link className="btn btn-origin-blue"
                                         target={`_blank`}
                                         rel={`nofollow`}
                                         to={{
                                             pathname: '/go-to-store',
                                             search: 'id=' + productOffer.potentialProductId +
                                                 '&mi=' + productOffer.productMainImageId
                                         }}>
                                    {/*<img*/}
                                    {/*    className="vendors-instance-buttons-country"*/}
                                    {/*    src={getCountryFlagImageUrl(toCountry)} alt="preview"/>*/}
                                    <div className="inline-block align-bottom">{getTranslation("visit_the_store").toUpperCase()}
                                    </div>
                                </Link>
                            }

                            {isDebugEnabled
                                ? <a href={getDebugProductUrl(productOffer.potentialProductId)} target='backend'
                                     className="backend-btn cursor-pointer">FIX</a>
                                : null}
                        </div>
                    </div>
                </div>
            })}

            {bottom}
        </div>
    }

    const product = props.product;

    return <div>
        <div className="vendors-wrapper">
            {product.productOffers && product.productOffers.length > 0
                ? <div className={`vendors-new-instances`}>
                    <h2 className="vendors-instances-header">{getTranslation("retailers")}</h2>
                    {renderList(product.productOffers)}
                </div>
                :
                <div className={`vendors-new-instances`}>
                    <h2 className="vendors-instances-header">{getTranslation("retailers")}</h2>

                    <div className={`text-center text-gray-600 pb-10`}>
                        <span className={`bg-slate-50 p-4 border rounded-md`}>
                            {getTranslation('no_available_primary_products')}
                        </span>
                    </div>
                </div>
            }
        </div>
    </div>
}
