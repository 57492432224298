import {DomainImage, ProductImage} from "../../App/Common/Image";
import {PlaceholderText} from "../../App/Common/Placeholder";
import {ProductVariantSelect} from "../../Product/Product/VariantSelect";
import {Price} from "../../Product/Price";
import React from "react";
import {getCountryFlagImageUrl} from "../../../Helpers/ImageHelper";
import {SpecificationRowHeader, SpecificationRowValue} from "../../Product/Specifications/SpecificationRow";

/** @see Product */
export function ProductTitleDemo() {
    return <div className="product-title">Product Title i7 16GB 480SSD Quadro M2000M</div>
}

/** @see Product */
export function ProductDescriptionDemo() {
    return <div className="product-image-description-block">
        <div className={`product-image-description-block-inner`}>
            <div className="product-image-description-columns">
                <div className="product-image-description-images-column">
                    <div className="product-image-description-images-main-column">
                        <div className="product-image-description-images-main-wrapper">
                            <ProductImage imageId={275965264} size={'l'} alt={`Demo product`}/>
                        </div>
                    </div>
                    <div className="product-image-description-images-secondary-column">
                        {[275965259, 275963868, 275963852].map((i, index) =>
                            <div
                                className={(index > 0 ? `mt-1 ` : ``) + `product-image-description-images-secondary-wrapper`}
                                key={'detailed-' + index}>
                                <ProductImage imageId={i} alt={`Demo product`} size={'xs'}/>
                            </div>)}
                    </div>
                </div>

                <div className="product-image-description-details-column">
                    <div className="product-image-description-details-wrapper">

                        <h3>
                            Manufacturer Name
                            Family Name 1 Family Name 2
                        </h3>

                        <div className="flex-1 mt-4">
                            <PlaceholderText wordsCount={95} seed={1234}/>
                        </div>

                        <div className="flex justify-between">
                            <div>

                            </div>

                            <div>
                                <ProductVariantSelect variants={
                                    {
                                        variant_group_id: 0,
                                        variant_attributes: [{
                                            attribute_name: "Variant Attribute Name", attribute_options: [
                                                {
                                                    option_name: 'Option Name 1',
                                                    selected: true,
                                                    merge_product_id: 0,
                                                    merge_product_gtin_id: 0,
                                                    merge_product_title: 'Product 1'
                                                },
                                                {
                                                    option_name: 'Option Name 2',
                                                    selected: false,
                                                    merge_product_id: 0,
                                                    merge_product_gtin_id: 0,
                                                    merge_product_title: 'Product 2'
                                                }
                                            ]
                                        }]
                                    }}/>
                            </div>
                        </div>

                        <div className="product-image-description-details-price">
                            <Price price={1234} currency={'Unit'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

/** @see VendorsTable */
export function ProductVendorsDemo() {
    return <div className="vendors-wrapper">
        <div className={`vendors-new-instances`}>
            <div className={`vendors-instance`}
                 key={'index'}>
                <div className="vendors-instance-image-column">
                    <div className="vendors-instance-image-wrapper">
                        <ProductImage imageId={275965264} size={'xs'} alt={`Demo product vendor`}/>
                    </div>
                </div>
                <div className="vendors-instance-domain-column">
                    <div className="vendors-instance-domain-wrapper">
                        <div className="vendors-instance-domain-image-column">
                            <div className="vendors-instance-domain-image-wrapper">
                                <DomainImage domainId={0}
                                             size={'xs'}
                                             alt={`Demo product`}/>
                            </div>
                        </div>
                        <div className="vendors-instance-domain-country-column">
                            <img className="vendors-instance-domain-country"
                                 src={getCountryFlagImageUrl('int')}
                                 alt="preview"/>
                        </div>
                        <div className="vendors-instance-domain-instance-column">
                            {'Vendor Name / Marketplace Name'}
                            <div>
                                {'Domain Name'}
                                <span className={`text-gray-400`}>{'Domain Instance Path'}</span>
                            </div>
                            <div className="text-gray-700">{'Instance Title'}</div>
                        </div>
                    </div>
                </div>
                <div className="vendors-instance-price-column">
                    <div className={`font-bold`}>
                        <Price price={1234} currency={'Unit'} unbuyable={false}/>
                    </div>
                </div>
                <div className="vendors-instance-status-column">
                    {'In Stock'}
                    <div>{'NEW'}</div>
                </div>
                <div className="vendors-instance-buttons-column">
                    <div className="btn btn-blue">
                        {/*<img*/}
                        {/*    className="vendors-instance-buttons-country"*/}
                        {/*    src={getCountryFlagImageUrl('int')} alt="preview"/>*/}
                        <div className="inline-block ml-1 align-bottom">{'Visit Store'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

/** @see SpecificationsTable */
export function ProductSpecificationsDemo() {
    return <table className="specification-table">
        <SpecificationRowHeader name="Specification Table Group Header" productCount={1}/>
        <SpecificationRowValue key="spec1" name="Label 1" value={['Value 1']} level={1}/>
        <SpecificationRowValue key="spec2" name="Label 2" value={['Value 2']} level={2}/>
        <SpecificationRowValue key="spec3" name="Label 3" value={['Value 3']} level={3}/>
        <SpecificationRowValue key="spec4" name="Label 4" value={['Value 4']} level={4}/>
        <SpecificationRowValue key="spec5" name="Label 5" value={['Value 1']} level={1}/>
    </table>
}