import {call, put, select, takeEvery} from "redux-saga/effects";
import {LOAD_TRACKING_PAGE_CHANGE, TrackingPageChangeAction, triggerVisited} from "../Action/tracking";
import axios, {AxiosResponse} from "axios";
import {getAxiosApiConfigs, serializePostParams} from "../../Helpers/ApiHelper";
import {handleShopitApi, handleShopitApiRequestError} from "./helper";
import {triggerError} from "../Action/interactive";

function* doPageChange(action: TrackingPageChangeAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/track/visit`,
        serializePostParams<IApiTrackVisitRequest>({
            url: action.newUrl,
            token: logToken || "",
            _logPageToken: logToken || null
        }),
        getAxiosApiConfigs(30000, "TR_OUT, notify developers"));

    const startTime = Date.now();
    let endTime = startTime;
    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
        endTime = Date.now();
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiTrackVisitResponse>(res)
        if (response) {
            if (response.trackingEnable) {

                if ((endTime - startTime) / 1000 > 5 && response.debugPoints && response.debugPoints.length) {
                    yield put(triggerError('0', 'Tracking Debug', response.debugPoints.join('\n')));
                }

                if (response.success) {
                    yield put(triggerVisited(response.token, action.newUrl));
                } else {
                    const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
                    if (isDebug) {
                        if (response.error) {
                            yield put(triggerError('0', 'Logger Error (Debug mode)', response.error.join('\n')));
                        } else {
                            yield put(triggerError('0', 'Logger Error (Debug mode)', 'Visit Failed (no details)'));
                        }
                    }
                }

            }
        }
    }
}

export function* watchTrackingAction() {
    yield takeEvery(LOAD_TRACKING_PAGE_CHANGE, doPageChange);
}
