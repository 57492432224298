import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {getSearchApiFromPageParams} from "./SearchHelper";

// originally this function was need for make post params string for object
// but i also add a type for it, make sure we request exactly object with type, but not a generic object
// but as hasOwnProperty is generic object function, have cast to any
// type i need for make sure i send no waste data. was a case when pass clientData or userSessionId in request
// while they needs to come from other source, not from params
export function serializePostParams<T>(obj: T, prefix?: string): string {
    var str = [],
        p;
    for (p in obj) {
        if ((obj as any).hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
                v = (obj as any)[p];
            if (v !== null && typeof v === "object") {
                str.push(serializePostParams(v, k))
            } else if (v !== null) {
                str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v))
            }
        }
    }
    return str.join("&");
}

export function getAxiosErrorDetails(error: AxiosError) {
    return `url: ${error.config.url}\n` +
        `method: ${error.config.method}\n` +
        `data: ${error.config.data}\n` +
        `timeout: ${error.config.timeout}\n` +
        `code: ${error.code}`
}

export function getAxiosApiConfigs(timeout: number | undefined = undefined, timeoutMessage: string | undefined = undefined): AxiosRequestConfig {
    return {
        timeout: timeout || 5000,       // 5 sec default,
        timeoutErrorMessage: timeoutMessage
    }
}

export async function fetchCategoryData(categoryIds: number[], tail: IApiTail) {
    const res: AxiosResponse<IApiResponse<IApiDataCategoryResponse>> = await axios.post('/api/data/category', serializePostParams<IApiDataCategoryRequest>(
        {
            categoryIds: categoryIds,
            returnLanguageId: "1",
            ...tail
        }), getAxiosApiConfigs(undefined, "F_CATEGORY_DATA_TIMEOUT, notify developers"))
    if (res && res.data && res.data.__shopitData) {
        return res.data.__shopitData.categories
    }
    return undefined
}

export async function fetchCategoryStructure(categoryId: number, tail: IApiTail) {
    const res: AxiosResponse<IApiResponse<IApiDataCategoryStructureResponse>> = await axios.post('/api/data/category/structure', serializePostParams<IApiDataCategoryStructureRequest>(
        {
            parentCategoryId: categoryId.toString(),
            returnLanguageId: "1",
            ...tail
        }), getAxiosApiConfigs(undefined, "F_CATEGORY_STRUCTURE_TIMEOUT, notify developers"))
    if (res && res.data && res.data.__shopitData) {
        return res.data.__shopitData.categories
    }
    return undefined
}

export async function fetchSelectedCategoryStructure(categoryId: number, tail: IApiTail) {
    const res: AxiosResponse<IApiResponse<IApiDataCategoryStructureResponse>> = await axios.post('/api/data/category/structure', serializePostParams<IApiDataCategoryStructureRequest>(
        {
            selectedCategoryId: categoryId.toString(),
            returnLanguageId: "1",
            ...tail
        }), getAxiosApiConfigs(undefined, "F_SELECTED_CATEGORY_STRUCTURE_TIMEOUT, notify developers"))
    if (res && res.data && res.data.__shopitData) {
        return res.data.__shopitData.categories
    }
    return undefined
}

export async function fetchMergedProduct(productId: number, useCache: boolean, tail: IApiTail) {
    const res: AxiosResponse<IApiResponse<IApiDataMergedProductResponse>> = await axios.post('/api/data/merged_product', serializePostParams<IApiDataMergedProductRequest>(
        {
            productId: productId.toString(),
            useCache: useCache,
            returnLanguageId: "1",
            ...tail
        }), getAxiosApiConfigs(undefined, "F_MERGED_PRODUCT_TIMEOUT, notify developers"))
    if (res && res.data && res.data.__shopitData) {
        return res.data.__shopitData
    }
    return undefined
}

export async function fetchCategorySearch(search: string, tail: IApiTail) {
    const res: AxiosResponse<IApiResponse<IApiDataCategorySearchResponse>> = await axios.post('/api/data/category/search', serializePostParams<IApiDataCategorySearchRequest>(
        {
            searchString: search,
            language: "1",
            ...tail
        }), getAxiosApiConfigs(undefined, "F_CATEGORY_SEARCH_TIMEOUT, notify developers"))
    if (res && res.data && res.data.__shopitData) {
        return {
            searchString: search,
            categories: res.data.__shopitData.categories
        }
    }
    return undefined
}

export async function fetchProduct(productId: number, tail: IApiTail) {
    const res: AxiosResponse<IApiResponse<IApiProductResponse>> = await axios.post(`/api/product/${productId}`, serializePostParams<IApiProductRequestPostParams>(
        {
            lang: 'sv',
            debug: false,
            ...tail
        }), getAxiosApiConfigs(undefined, "F_PRODUCT_TIMEOUT, notify developers"))
    if (res && res.data && res.data.__shopitData) {
        return res.data.__shopitData
    }
    return undefined
}

export async function fetchProducts(productIds: number[], tail: IApiTail) {
    const res: AxiosResponse<IApiResponse<IApiProductsResponse>> = await axios.post(`/api/products/${productIds.join(',')}`, serializePostParams<IApiProductRequestPostParams>(
        {
            lang: 'sv',
            debug: false,
            ...tail
        }), getAxiosApiConfigs(undefined, "F_PRODUCT_LIST_TIMEOUT, notify developers"))
    if (res && res.data && res.data.__shopitData) {
        return res.data.__shopitData
    }
    return undefined
}

export async function fetchImageVerifications(imageIds: number[], tail: IApiTail) {
    const res: AxiosResponse<IApiResponse<IApiUserDataResponse>> = await axios.post(`/api/user/data`, serializePostParams<IApiUserDataRequest>(
        {
            userId: null,
            imageIds: imageIds,
            productIds: null,
            ...tail
        }), getAxiosApiConfigs(undefined, "F_IMAGE_VERIFICATION_TIMEOUT, notify developers"))
    if (res && res.data && res.data.__shopitData) {
        return res.data.__shopitData.shophunterData.verifiedImages
    }
    return undefined
}

export async function fetchSearchDebugData(params: IUrlResolvedParams, tail: IApiTail) {
    const searchQuery = getSearchApiFromPageParams(params)

    const res: AxiosResponse<IApiResponse<IApiSearchLogResponse>> = await axios.post(`/api/debug/log?${searchQuery}`, serializePostParams<IApiProductRequestPostParams>(
        {
            lang: 'sv',
            debug: false,
            ...tail
        }), getAxiosApiConfigs(undefined, "F_SEARCH_DEBUG_TIMEOUT, notify developers"))

    if (res && res.data && res.data.__shopitData) {
        return res.data.__shopitData
    }
    return undefined
}

export async function fetchCategorySubstructure(categoryId: number, tail: IApiTail) {

    const res: AxiosResponse<IApiResponse<IApiCategoryStructureResponse>> = await axios.post(`/api/category`, serializePostParams<IApiCategoryRequest>(
        {
            lang: 'sv',
            mode: "sc",
            categoryId: categoryId,
            ...tail
        }), getAxiosApiConfigs(undefined, "F_SEARCH_DEBUG_TIMEOUT, notify developers"))

    if (res && res.data && res.data.__shopitData) {
        return res.data.__shopitData
    }
    return undefined
}

export async function fetchTrendsData(tail: IApiTail) {
    const res: AxiosResponse<IApiResponse<IApiDataTrendsResponse>> = await axios.post('/api/data/trends', serializePostParams<IApiDataTrendsRequest>(
        tail), getAxiosApiConfigs(undefined, "F_TRENDS_TIMEOUT, notify developers"))
    if (res && res.data && res.data.__shopitData) {
        return res.data.__shopitData.trends
    }
    return undefined
}
