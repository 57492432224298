import React from "react";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import LocaleLink from "../Links/LocaleLink";

const ComponentTranslationKey = "Footer"

/** @see FooterDemo */
export default function Footer(props: {}) {

    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    const propsExtended = useSelector((state: IAppState) => {
        return {
            visibleFilters: state.interactive.showFilters,
            visibleCategories: state.interactive.showCategories,

            debugEnabled: state.config.debug,
        }
    })

    return <div className="footer-panel-wrapper">
        <div className="footer-panel max-w-[100vw]">
            <div
                className={`filter-overlay-padding-animation ${propsExtended.visibleFilters || propsExtended.visibleCategories ? `filter-overlay-padding` : `cards-list-container-padding-compensate`}`}>

                <div className={`cards-list-container mx-auto`}>
                    <div className={`footer-columns mt-6`}>
                        <div className={`footer-column-description`}>
                            <img className={`-mt-1 grayscale`} src="/resources/shopit-logo-transparent-small.png"
                                 alt="Shopit"/>
                            <div className={`text-neutral-500 text-lg mt-3 pr-8 leading-6`}>
                                <div className={`pb-2`}>
                                    Shopit är sveriges nya AI-baserade shoppingsökmotor där du som konsument kan söka
                                    och jämföra miljontals produkter i över 90 000 kategorier för att hitta rätt produkt
                                    och butik att handla hos.
                                </div>
                            </div>
                        </div>

                        <div className={`footer-column-space order-2`}></div>

                        <div className={`footer-column-links order-3`}>
                            <h3 className={`footer-column-header`}>
                                {getTranslation('about_us')}
                            </h3>
                            <ul className={`footer-column-links-list`}>

                                <li><LocaleLink to={'/om'} toLocale={{en: '/about'}}>
                                    {getTranslation('about_shopit')}
                                </LocaleLink></li>

                                <li><LocaleLink to={'/kontakt'} toLocale={{en: '/contact'}}>
                                    {getTranslation('contact')}
                                </LocaleLink></li>

                                {/*<li><a href="#">{getTranslation('work_at_shopit')}</a></li>*/}
                                {/*<li><a href="#">{this.getTranslation('news')}</a></li>*/}

                                <li><LocaleLink to={'/ansvarsbegransning'} toLocale={{en: '/liability'}}>
                                    {getTranslation('disclaimer_and_limitations')}
                                </LocaleLink></li>
                            </ul>
                        </div>

                        <div className={`footer-column-links order-4`}>
                            <h3 className={`footer-column-header`}>
                                {getTranslation('stores')}
                            </h3>
                            <ul className={`footer-column-links-list`}>

                                <li><LocaleLink to={'/lagg-till-din-butik'} toLocale={{en: '/add-your-store'}}>
                                    {getTranslation('to_on_shopit')}
                                </LocaleLink></li>

                                {/*<li><span className={`link`} onClick={() => {*/}
                                {/*    setState({...state, isStoreRegisterOpen: true})*/}
                                {/*}}>*/}
                                {/*    {getTranslation('add_your_store')}*/}
                                {/*</span></li>*/}
                                <li><a href={`https://business.shopit.com/store/register`} className={`link`} target={`_blank`}>
                                    {getTranslation('add_your_store')}
                                </a></li>
                                <li><a href={`https://business.shopit.com/account/login`} className={`link`} target={`_blank`}>
                                    {getTranslation('login_for_stores')}
                                </a></li>
                            </ul>
                        </div>

                        <div className={`footer-column-links order-5`}>
                            <h3 className={`footer-column-header`}>
                                {getTranslation('consumers')}
                            </h3>
                            <ul className={`footer-column-links-list`}>

                                {/*<li><LocaleLink to="/sa-funkar-det" toLocale={{en: `/how-it-works`}}>*/}
                                {/*    {getTranslation('how_it_works')}*/}
                                {/*</LocaleLink></li>*/}

                                {/*<li><LocaleLink to="/anvandarpolicy" toLocale={{en: `/personal-policy`}}>*/}
                                {/*    {getTranslation('personal_data_policy')}*/}
                                {/*</LocaleLink></li>*/}

                                <li><Link to="/cookie-policy">{getTranslation('cookie_policy')}</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className={`flex items-center mt-4 pb-6 border-t`}>
                        <div className={`text-gray-500 text-lg pt-2`}>
                            Copyright 2024 Shopit Online Europe AB. All rights reserved.
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
}
