import {TranslationSet} from "./i18n.constants";

export const svTranslation: TranslationSet = {
    Footer: {
        add_store_brand: 'Lägg till butik / varumärke',

        about_us: 'Om oss',
        about_shopit: 'Om Shopit',
        contact: 'Kontakt',
        work_at_shopit: 'Jobba hos oss',
        news: 'Nyheter',
        disclaimer_and_limitations: 'Friskrivning & ansvarsbegränsning',
        personal_data_policy: 'Personuppgiftspolicy',
        cookie_policy: 'Cookiepolicy',

        stores: 'Butiker',
        to_on_shopit: 'Att synas på Shopit',
        add_your_store: 'Lägg till din butik',
        login_for_stores: 'Login för butiker',

        consumers: 'Konsumenter',
        how_it_works: 'Så funkar det'
    },
    FilterPanel: {
        manufacturer: 'Tillverkare/Varumärken',
        price: 'Pris',
        color: 'Färg',
        extra: 'Extras',
        mainType: 'Huvudtyp'
    },
    FilterColor: {
        selectColor: 'Visuell Färg'
    },
    FilterStaticManufacturer: {
        search: 'Sök',
        manufacturer: 'Tillverkare/Varumärken',
        show: 'Visa',
        more_options: 'fler val',
        show_less_options: 'Visa färre val',
        no_result: 'Ingenting hittades',
    },
    FilterStaticBranding: {
        search: 'Sök',
        brands: 'Varumärken',
        show: 'Visa',
        more_options: 'fler val',
        show_less_options: 'Visa färre val',
        no_result: 'Ingenting hittades',
    },
    FilterStaticUniqueNamePath: {
        show: 'Visa',
        more_options: 'fler val',
        show_less_options: 'Visa färre val',
        search: 'Sök',
        no_result: 'Ingenting hittades',
    },
    FilterStaticStat: {
        image: 'Bilder',
        video: 'Videoklipp',
        comment: 'Kommentarer',
        share: 'Delningar'
    },
    SearchBox: {
        placeholder: 'Vad funderar du på att köpa?',
        search: 'Sök',
        discover: 'Upptäck'
    },
    SearchAsYouType: {
        product: 'Produkter',
        productSecondary: 'Produkter utan pris / bild etc',
        category: 'Kategorier',
        manufacturer: 'Tillverkare / produktfamiljer',
        branding: 'Brandning',
        vendor: 'Butiker',
        code: 'Modell- / EAN-koder',
        other: 'Fritextsök',
        unknown: 'Övriga',
        error: 'Någonting gick fel, försök igen om ett par minuter.',
        no_result: 'Ingenting hittades',
        seconds: 'sekunder',
        uncategorized: "Okategoriserad",
        in: "i",
        from: "från",
        uniqueNamePath: "Produktmodell",
        relatedUniqueNamePathCollection: "Tillbehör & Reservdelar",
        searchResultsFor: 'Sökresultat för'
    },
    TopBar: {
        show_debug: 'Visa debug',
        list_debug_info: 'List debug information',
        categories: 'Kategorier',
        filters: 'Filter',
        hint_filters: 'Visa filterpanelen',
        hint_categories: 'Visa kategoriträdet',
        hint_remove_filter: 'Ta bort filter',
        other_manufacturers: 'Övriga tillverkare',
        FilterBarNumericButton: {
            price: 'Pris'
        }
    },
    Grouping: {
        sort_by: 'Sortera efter',
        manufacturer: {
            rank: 'Tillverkare - Rank',
            price_lowest: 'Tillverkare - Lägsta Pris',
            price_highest: 'Tillverkare - Högsta Pris'
        },
        branding: {
            rank: 'Brandning - Rank',
            price_lowest: 'Brandning - Lägsta Pris',
            price_highest: 'Brandning - Högsta Pris'
        },
        retailer: {
            rank: 'Butiker - Rank',
            price_lowest: 'Butiker - Lägsta Pris',
            price_highest: 'Butiker - Högsta Pris'
        },
        price: {
            lowest: 'Lägsta Pris',
            highest: 'Högsta Pris'
        }
    },
    LoginBox: {
        login_button_text: 'logga in'
    },
    StoreBox: {
        store_name: 'Butikens Namn',
        store_website_url: 'Butikens Webbplats-URL',
        feed_url: 'Butikens Feed-URL',
        login_email_for_admin: 'E-postadress (för admin)',

        login_email: 'E-postadress',
        password: 'Lösenord',
        forgot_password: 'Glömt lösenordet',
        log_in: 'Logga in',

        restore_password: 'Aterstall lösenordet',
        submit: 'Skicka',

        company_name: 'Företagsnamn',
        post_address: 'Postadress',
        post_code: 'Postnr',
        post_city: 'Postort',
        organization_number: 'Organisationsnummer',

        contact_name: 'Namn kontaktperson',
        contact_phone: 'Telefon kontaktperosn',

        next: 'NÄSTA',
        message: 'Ett e-postmeddelande har skickats till "<email>". Klicka på länken i meddelandet för att slutföra registreringen'
    },
    NavigationBox: {
        login: 'Logga in',
        logout: 'Logga ut',
        settings: 'Min Profil',
        manager_settings: 'Förvalta',
        show_debug: 'Visa debug'
    },
    ListPagination: {
        previous: 'föregående',
        next: 'nästa'
    },
    Item: {
        merge: 'Är detta samma produkt?',
        follow: 'Följ',
        branding: 'Brandning',
        manufacturer: 'Tillverkare',
        vendor: 'Butik',
        show: 'Visa',
        products: 'produkter'
    },
    LegendControls: {
        verification_modal_title: 'Välj rätt kategori för denna produkt',
        own: 'Lägg till ägda produkter',
        own_active: 'Ta bort från ägda produkter',
        like: 'Lägg till gillade produkter',
        like_active: 'Ta bort från gillade produkter',
        compare: 'Jämför denna produkt',
        compare_active: 'Ta bort från jämför produkter',
        verify: 'Produkten är i FEL kategori',
        verify_active: 'Produkten är i FEL kategori',
        verify_category: 'Produkten är i RÄTT kategori',
        verify_category_active: 'Produkten är i RÄTT kategori'
    },
    ShowMore: {
        show_additional: 'Visa ytterligare',
        without_price: 'utan bild/pris',
        products: 'produkter',
        other_manufacturers: 'Övriga tillverkare',
        other_retailers: 'Övriga butiker',
        other_brands: 'Övriga varumärken',
        from: 'från'
    },
    TableList: {
        product: 'Produkt',
        name: 'Namn',
        price: 'Pris'
    },
    Separator: {
        other_manufacturers: 'Övriga tillverkare',
        other_retailers: 'Övriga butiker',
        other_brands: 'Övriga varumärken',
        other: 'Övriga',
        fold: 'Dölj',
        unfold: 'Visa',
        branding: 'Brandning',
        manufacturer: 'Tillverkare',
        vendor: 'Butik',
        family: 'Familj',
        family_chain: 'Familje kedja',
        no_product_family: 'Övriga',
    },
    ProductsGrid: {
        for : 'till'
    },
    Product: {
        edit_image: 'editera',
        image_feedback: 'Vad tycker du om bilden?',
        price_from: 'Pris från',
        CategoryPathBar: {
            uncategorized: "Okategoriserad"
        },
        TabsSection: {
            retailers: 'Butiker',
            prices: 'Priser',
            specifications: 'Specifikationer',
            reviews: 'Recensioner',
            videos: 'Videor',
            accessories: 'Tillbehör',
            parts: 'Reservdelar',
            for: 'för',
            no_instances: 'Tyvärr, vi har inga butiker som säljer denna produkt just nu'
        },
        Tab: {
            ProductOffers: {
                discontinued: 'Utgått',
                too_old: 'För gammal data',
                in_stock: 'I lager',
                backorder: 'Beställningsvara',
                out_of_stock: 'Ej i lager',
                manufactured_on_order: 'Tillverkad på beställning',
                pre_order: 'Förboka',
                stock_unknown: 'Okänt',
                visit_the_store: 'Till butiken',
                store: 'Butik',
                product: 'Produkt',
                price: 'Pris',
                including_VAT: 'inkl moms',
                availability: 'Tillgänglighet',
                link: 'Länk',
                international_products: 'Internationella butiker',
                available_vendors: 'Tillgängliga butiker',
                duplicate_vendors: '[D] Dubletter',
                only_international_vendors: 'Internationella butiker',
                previous_vendors: 'Tidigare butiker',
                price_out_of_range: '[D] Pris utanför omfång',
                status_not_buyable: '[D] Ej köpbar',
                no_available_primary_products: 'Vi har inga butiker som säljer denna produkten för närvarande',
                show_more_used_refurbished_products_part_1: 'Visa',
                show_more_used_refurbished_products_part_2: 'begagnade / rekonditionerade produkter',
                retailers: 'Butiker',
                user_or_refurbished: 'Begagnade / Rekonditionerade',
                day : "dag",
                days : "dagar",
                from : "från",
                up_too : "upp till",
                not_paid : "Denna butik är inte en betalande kund hos Shopit och har därför ingen direktlänk till produkten.",
                stats : "Vi har hittat produkten i totalt <total_offers_count> butiker, varav <active_offers_count> är betalande kunder hos Shopit och har en direktlänk till butiken."
            },
            Specifications: {
                product_removed: 'Produkten är borttagen från Shopit',
                Attributes: {
                    attributes: 'Produktspecifikationer',
                    yes: 'Ja',
                    no: 'Nej',
                    generic: 'Övriga Attribut',
                    mainType: 'Huvudtyp'
                },
                Brands: {
                    manufacturer_model: 'Tillverkare / Modell',
                    manufacturer: 'Tillverkare',
                    model: 'Modell',
                    branding_person: 'Varumärken / Personer',
                    branding: 'Brandning',
                    person: 'Person / Designer',
                },
                Colors: {
                    color: 'Färg',
                    pattern: 'Mönster',
                    colors: 'Visuell Färg'
                },
                Codes: {
                    model_codes: 'Modellkoder',
                    sku: 'SKU',
                    MODEL_CODE: 'Produktmodellkod',
                    GTIN_SKU_UNKNOWN: 'Art.nr',
                    MODEL_VERSION: 'Version',
                    MODEL_YEAR: 'Årsmodell',
                    GTIN_EAN_GS1: 'GS1/EAN-kod',
                    GTIN_ISBN: 'ISBN-kod',
                    GTIN_ISSN: 'ISSN-kod',
                    GTIN_ISAN: 'ISAN-kod',
                    GTIN_UPC: 'UPC-kod',
                    GTIN_JPN: 'JPN-kod',
                    GTIN_RSK: 'RSK-kod',
                    SKU_RETAILER: 'Butikens art.nr',
                    SKU_MANUFACTURER: 'Tillverkarens art.nr',
                    SKU_ASIN: 'ASIN-kod'
                }
            }
        }
    },
    CompareProducts: {
        title: 'Jämför produkter i',
        compare: 'Jämför',
        add_to_compare: 'Lägg till produkter för att jämföra',
        hint_compare_active: 'Visa produktjämförelserna',
        hint_compare_inactive: 'Markera först produkter i listan som du vill jämföra',
    },
    MergeProducts: {
        title: 'Samma produkt',
        same: 'Detta är samma produkt',
        merge: 'Samma produkt',
        dont_merge: 'Inte samma produkt'
    },
    CategoryVerification: {
        suggest_search_category: 'Förslag / Sök',
        search_category: 'Sök kategori',
        last_selected_categories: 'Senaste Valda',
        we_are_sorry: 'Vi är ledsna...',
        nothing_to_suggest: 'men tyvärr är inte katgoriförslagen tillgängliga för tillfället. Vänligen försök igen om en liten stund.',
        try_again: 'Försök igen',
        no_categories_were_found: 'No categories were found',
        no_latest_categories: 'You do not have latest categories',
        search_for_category_suggestion: 'Söker efter kategoriförslag...',
        product_archived: 'Product Archived',
        product_duplicate: 'Product Duplicate',
        product_not_found: 'Product Not Found',
        cancel: 'Avbryt',
        cancel_modal_title: '',
        cancel_modal_message: 'Vill du AVBRYTA flytten av produkten till en annan kategori?',
        title: 'Ange produktens korrekta kategori'
    },
    CategoryVerificationMenu: {
        no_latest_categories: 'You do not have latest categories',
    },
    DetailsPage: {
        follow: 'Följ',
        map: 'Karta',
        map_discloser: 'För tillfället har vi ingen positionerings data, men vi jobbar hårt på att tillhandaha det i framtiden.',
        are_you_responsible: 'Är du ansvarig för detta varumärke?',
        be_responsible: 'Klicka här för att ta ansvar över',
        verify_as_manager_title: 'Ansvara för'
    },
    Brand: {
        followers: 'Följare',
        promotions: 'Kampanjer',
        products: 'Produkter',
        local_stores: 'Lokala butiker'
    },
    Retailer: {
        followers: 'Följare',
        promotions: 'Kampanjer',
        products: 'Produkter'
    },
    TitleBar: {
        na: 'Denna kategori är fortfarande under utveckling. Hjälp oss göra den bättre!',
        of: 'av',
        results: 'resultat',
        active: 'aktiva',
        seconds: 'sekunder',
        search_for: 'Sökningen på',
        yielded_many_results: 'gav flera resultat',
        fix_category_modal_title: 'Du kan hjälpa oss göra den här kategorin bättre',
        vendor: 'Butiken',
        branding: 'Varumärket',
        manufacturer: 'Tillverkaren',
        has_products_in_category: 'har produkter i följande kategorier',
        folowing_categories_contains_products_with_text: 'Följande kategorier innehåller produkter med texten',
        search_results_for: 'Sökresultat för',
    },
    FixCategory: {},
    Login: {
        one_click_away: 'Du är bara ett klick bort!',
        almost_there: 'Nästan där',
        unlimited_shopping: 'en värld av obegränsad shopping',
        create_account: 'Skapa Shopit konto',
        facebook_login: 'Logga in med Facebook',
        feed_disclaimer: 'Vi kommer aldrig posta någonting i din feed om du inte delar det själv.',
        existing_account: 'Logga in med ett befintligt konto',
        forgot_password: 'Glömt ditt lösenord?',
        unable_to_login: 'Kunde inte logga in, försök igen om ett par minuter',
        email: 'E-post',
        password: 'Lösenord',
        logged_in: 'Inloggad',
        log_in: 'Logga in',
        login: 'Logga in',

        or: 'eller',

        new_user: 'Ny användare',
        create_new_user: 'Skapa ny användare',
        first_name: 'Förnamn',
        last_name: 'Efternamn',

        validation: {
            wrong_email_or_password: 'Fel e-post eller lösenord.',
            cannot_log_in: 'Kunde inte logga in. Försök igen om ett par minuter.'
        }
    },
    Registration: {
        welcome: 'Välkommen till Shopit!',
        full_power_of_shopit: 'Gör dig redo att uppleva den fulla effekten av Shopit!',
        create_account: 'Skapa konto',
        unable_to_register: 'Kunde inte skapa konto, försök igen om ett par minuter',
        first_name: 'Förnamn',
        last_name: 'Efternamn',
        email: 'E-post',
        email_repeated: 'Upprepa Epost',
        password: 'Lösenord',
        registered: 'Konto skapat!',
        validation: {
            password_length: 'Lösenordet måste vara 6-72 tecken långt.',
            email_taken: 'Det finns redan ett konto registrerat med den angivna e-post adressen.',
            emails_mismatch: 'De angivna e-port adresserna matchar inte.'
        }
    },
    RestrictedPage: {
        need_to_be_logged: 'Du behöver vara inloggad för att visa denna sida'
    },
    User: {
        Page: {
            Boards: {
                my_private_boards: 'Mina privata samlingar',
                my_public_boards: 'Mina publika samlingar',
                my_owned_products: 'Produkter jag redan äger',
                my_liked_products: 'Produkter jag gillar / vill köpa',
                my_compare_products: 'Produkter jag jämför',
                new_board: 'Ny samling',
                new_public_board: 'Ny publik samling',
                create: 'Skapa'
            }
        },
        Settings: {
            Tab: {
                User: {
                    title: 'Namn & Användare',
                    sub_title: 'Byt lösenord och uppdatera dina uppgifter.',
                    first_name: 'Förnamn',
                    last_name: 'Efternamn',
                    email: 'E-post',
                    old_password: 'Gammalt Lösenord',
                    new_password: 'Nytt Lösenord',
                    repeat_new_password: 'Upprepat Lösenord',
                    year: 'År',
                    month: 'Månad',
                    day: 'Dag',
                    born: 'Född',
                    gender: 'Kön',
                    male: 'Man',
                    female: 'Kvinna',
                    missing_password: 'För att ändra lösenord, skriv in nytt lösenord (upprepat)',
                    password_missmatch: 'Nytt och upprepat lösenord matchar inte',
                    wrong_password: 'Fel lösenord'
                },
                Description: {
                    title: 'Bild & Beskrivning',
                    sub_title: 'Byt profilbild och bakgrundsbild. Du kan också fylla i en beskrivning om dig själv.',
                    avatar: 'Avatar',
                    description: 'Beskrivning',
                    background: 'Bakgrundsbild'
                },
                Localization: {
                    title: 'Plats & Språk',
                    sub_title: 'Byt språk och land. Genom att fylla i var du bor kan vi även visa var du kan hitta produkter som säljs nära dig!',
                    address: 'Adress',
                    zip_code: 'Postnummer',
                    city: 'Postort',
                    country: 'Land',
                    currency: 'Valuta',
                    language: 'Språk',
                    language_code: {
                        SV: 'Svenska',
                        EN: 'Engelska',
                        INT: 'Internationell'
                    }
                },
                Shopping: {
                    title: 'Shopping DNA',
                    sub_title: 'Vi vill veta lite mer om dig. Berätta vem du är genom att dra parametrarna mot det du kan identifiera dig med.'
                },
                Interests: {
                    title: 'Intresseområden',
                    sub_title: 'Fyll i alla dina intressen. Genom att göra det kan vi ge dig ännu bättre sökresultat och hitta produkter som du gillar lite extra mycket.'
                },
                Privacy: {
                    title: 'Sekretess & Filtrering',
                    sub_title: 'Här kan du ställa in din sekretess nivå och vilka produkter du vill filtrera ut.'
                }
            }
        }
    },
    Form: {
        success_message: 'Sparad',
        drag_and_drop: 'Dra bild för att ladda upp',
        or_click_to_select: 'eller klicka för att välja',
        save: 'Spara',
        submit: 'Skicka'
    },
    Image: {
        no_image: 'ingen bild',
        not_found: 'ej funnen',
        fullsize: 'Visa i full storlek',
        feedback: 'Vad tycker du om bilden?',
        edit: 'editera'
    },
    ImageVerificationMenu: {
        status_main: "Main Product Image",
        status_unrelated: "Unrelated For Product",
        status_ok_image: "OK for Product",
        status_accessory: "Accessory for Product",

        type_isolated: 'Isolated',
        type_light_gradient: 'Light Gradient',
        type_gradient: 'Gradient',
        type_solid: 'Solid',
        type_homogen: 'Homogen',
        type_drawing: 'B&W Drawing',
        type_bad_soft_pixels: 'Bad Soft Pixels',
        type_bad_uncropped: 'Bad Uncropped',

        content_type_product_one: 'Product (One)',
        content_type_product_in_usage: 'Product in Use',
        content_type_product_multiple_variants: 'Multiple Variants',
        content_type_product_multiple_same: 'Multiple Same',
        content_type_product_one_accessory_or_part: 'One Accessory / Part',
        content_type_product_set_or_collection: 'Set or Collection',
        content_type_product_screenshot: 'Screenshot',
        content_type_product_and_cover: 'Product with Cover',
        content_type_product_cover_only: 'Product Cover Only',
        content_type_package_unique: 'Package (Unique)',
        content_type_package_back: 'Package Back',
        content_type_package_generic: 'Package Generic',
        content_type_package_and_product: 'Package and Product',
        content_type_perspective_closed: 'Persp_ Closeup',
        content_type_perspective_front: 'Persp_ Front',
        content_type_perspective_side: 'Persp_ Side',
        content_type_perspective_top: 'Persp_ Top',
        content_type_perspective_bottom: 'Persp_ Bottom',
        content_type_perspective_back: 'Persp_ Back',
        content_type_perspective_open: 'Persp_ Inside / Open',
        content_type_perspective_closed_folded: 'Persp_ Folded / Closed',
        content_type_perspective_multiple: 'Persp_ Multiple',
        content_type_perspective_environment: 'Persp_ Environment',
        content_type_icon_no_image: 'Icon No Image',
        content_type_icon_campaign: 'Icon Campaign',
        content_type_icon_symbol_or_function: 'Symbol / Function',
        content_type_icon_website_design: 'Website Design',
        content_type_logo_brand: 'Brand Logo',
        content_type_logo_vendor: 'Vendor Logo',
        content_type_logo_product: 'Product Logo',
        content_type_logo_tech: 'Tech Logo',
        content_type_multiple_logos: 'Multiple Logos',
        content_type_text: 'Text',
        content_type_collage_text_image: 'Collage (Text / Image)',
        content_type_drawing_blueprint: 'Drawing Blueprint',
        content_type_drawing_3d: 'Drawing 3D',
        content_type_drawing_illustration: 'Drawing Illustration',
        content_type_chart_graph: 'Chart Graph',
        content_type_chart_size: 'Chart Sizes',
        content_type_chart_energy_certificate: 'Chart Energy Certificate',
        content_type_human_model_focus_top: 'Human Focus Top',
        content_type_human_model_focus_bottom: 'Human Focus Bottom',
        content_type_human_model_full: 'Human Full',
        content_type_human_model_closeup: 'Human Closeup',
        content_type_human_model_side: 'Human Side',
        content_type_human_model_back: 'Human Back',
        content_type_human_model_head: 'Human Head',
        content_type_human_model_hands: 'Human Hands',
        content_type_human_model_feet: 'Human Feet/Legs',
        content_type_human_model_sitting: 'Human Sitting',
        content_type_human_model_multi_perspective: 'Human Multi-Persp_',
        content_type_human_model_multi_variants: 'Human Multi-Variant',
        content_type_human_model_environment: 'Human Environment',
        content_type_book_media_cover: 'Book / Media Cover',
        content_type_book_media_cover_back: 'Book / Media Cover Back',
        content_type_example_palette_color_material: 'Multi Colors / Materials',
        content_type_example_one_material_color: 'One Color / Material',
        content_type_example_one_pattern: 'One Pattern',
        content_type_bad_unrelated: 'Unrelated',

        overlay_overlay_text: 'Overlay Text',
        overlay_overlay_symbol: 'Overlay Symbol',
        overlay_overlay_logo: 'Overlay Logo',
        overlay_overlay_campaign: 'Overlay Campaign',
        overlay_overlay_measurement: 'Overlay Measurement',
        overlay_overlay_closeup: 'Overlay Closeup',
        overlay_overlay_graphics: 'Overlay Graphics',
        overlay_overlay_false: 'No Overlay',

        watermark_watermark_true: 'Watermark',
        watermark_watermark_false: 'Not Watermark',

        variants_0: 'No Variants',
        variants_1: '1 Variants',
        variants_2: '2 Variants',
        variants_3: '3 Variants',
        variants_4: '4 Variants',
        variants_5: '5+ Variants',

        delete: 'Nollställ',
        view_more: 'Visa fler typer',
        edit_image_type: 'Redigera bildtyp'
    },
    Recover: {
        recover_your_account: 'Recover Your Account',
        email: 'Email',
        email_sent: 'Instructions has been sent to your email',
        email_not_found: 'We did not recognize your email, please try again',
        email_invalid: 'Email you have entered is invalid',
        not_able_to_process_request: 'We are not able to process request, please try again in a few moments.',
        send: 'Send',
        cancel: 'Cancel',
        RecoverAccept: {
            enter_new_password: 'Enter New Password',
            password: 'Password',
            password_repeat: 'Repeat Password',
            password_error_does_not_match: 'Passwords do not match',
            password_error_too_long_or_short: 'Password must be at between 6 and 72 symbols',
            password_has_been_changed: 'Your password has been successfully changed.',
            redirect_in_10_seconds: 'You will be redirected to main page in 10 seconds.',
            not_able_to_process_request: 'We are not able to process request, please try again in a few moments.',
            url_invalid: 'It seems like url you are using is not valid anymore, please request a new one',
            change: 'Change',
            cancel: 'Cancel'
        }
    },
    FeedbackWithNavigation: {
        content_is_not_available: 'content_is_not_available',
        go_back_to_the_previous_page: 'go_back_to_the_previous_page',
        go_to_main_page: 'go_to_main_page',
        the_link_expired_or_unavailable: 'The link you followed have expired or unavailable',
        not_able_to_process_request: 'We are not able to process request, please try again in a few moments.',
        email_confirmation: 'Email confirmation',
        email_already_registered: 'Email you provided already in use'
    },
    FeedbackWithRedirect: {
        cancel_account_recover: 'Cancel Account Recover',
        cancel_account_success: 'Your request for the new password has been canceled.',
        redirect_in_10_seconds: 'You will be redirected to main page in 10 seconds.',
        email_confirmation: 'Email confirmation',
        email_confirmed: 'Email successfully confirmed.'
    },
    Messages: {
        taking_a_break: 'Verkar som att sökmotorn tar en paus, försök igen om en liten stund...',
        filter_title: 'Filtreringen gav inga resultat...',
        too_many_filters: '...ta bort ett par filter och försök igen.',
        sorry_title: 'Vi är ledsna...',
        sorry: 'Tyvärr...',
        no_results: '...men tyvärr matchade inte din sökning några resultat.\nProva med att ändra din sökning lite för att få fler träffar.',
        product: '...men denna produkt hittades inte på Shopit längre.',
        retailer: '...men denna butik hittades inte på Shopit längre.',
        brand: '...men detta varumärke hittades inte på Shopit längre.',
        unknown_error: 'Någonting gick fel, försök igen om ett par minuter',
        category_not_available: 'Kategorin är inte tillgänglig just nu',
        sorry_empty_category: 'Vi är ledsna, men det finns inga produkter i denna kategorin just nu...',
        sorry_come_back_later: '...titta gärna in igen lite senare',
    },
    Manage: {
        add: 'Lägg till',
        domain: 'www.domän.se',
        missing_a_vendor: 'Saknar du en butik eller ett varumärke i Shopit?',
        enter_a_website: 'Ange dess webbadress nedan så lägger vi till den!',
        Domain: {
            email: 'Din e-post',
            send_request: 'Skicka förfrågan',
            request_sent: 'Ett verifierings mail har skickats. Följ instruktionerna i det för att slutföra din förfrågan.',
            not_logged_in: 'Logga in för att begära domänåtkomst.',
            invalid_email: 'Ogiltig e-post.',
            something_went_wrong: 'Någonting gick fel, försök om ett tag igen.',
            confirm_domain_request: 'Bekräfta domän access',
            confirm_domain_request_for: 'Bekräfta domän access för',
            finish_request: 'Slutför förfrågan',
            domain_already_managed: 'Domänen hanteras redan av någon.',
            request_accepted: 'Förfrågan slutförd.',
            request_rejected: 'Förfrågan avbruten.',
            code_not_found: 'Ogiltig verifierings kod. Koden kan ha löpt ut, begär en ny kod och försök igen.',
            unauthorized: 'Du är inte auktoriserad för att begära åtkomst till domänen.',
            cancel: 'avbryt'
        },
        Tab: {
            Feed: {
                title: 'Feed',
                sub_title: 'Inställningar för din feed.'
            },
            Statistics: {
                title: 'Statistik',
                sub_title: 'Här kan du se statistik för dina domäner och produkter.'
            },
            PaidLinks: {
                title: 'Betalda länkar',
                sub_title: 'Konfigurera dina betalda länkar.'
            }
        }
    },
    External: {
        wait: "Strax där...",
        redirecting: "...vi skickar dig nu vidare till butiken"
    },
    Attribute: {
        yes: 'Ja',
        no: 'Nej',
        show: 'Visa',
        more_options: 'fler val',
        show_less_options: 'Visa färre val'
    },
    Price: {
        uncertain: 'Osäkert',
        discontinued: 'Utgått'
    },
    ConfirmModal: {
        yes: 'Ja',
        no: 'Nej'
    },
    ProductVariants: {
        select_variant: 'Välj variant',
        select_size: 'Välj storlek',
        select_color: 'Välj färg',
    },
    SearchRoute: {
        placeholder_card: 'Platshållarkort',
        placeholder_product: 'Platshållarprodukt',
        most_popular_in: 'Mest populära produkterna i',
        product_not_found_anymore: 'Produkten hittades inte längre',
    },
    CookiePolicy: {
        title: 'Cookies och liknande lagringsteknik',
        policy: 'Shopit använder cookies och liknande tekniker för att samla in och behandla uppgifter såsom din IP-adress och information om hur du använder webbplatsen för analys och statistik, för att ge dig en bättre kundupplevelse och för relevant marknadsföring. Genom att klicka ”Acceptera” bekräftar du att du har läst vår cookiepolicy och att du samtycker till vår användning av cookies och liknande tekniker. Du kan anpassa dina inställningar genom att klicka ”Inställningar”, där du också hittar mer information om för vilka ändamål vi använder oss av cookies. Här kan du läsa mer om vår användning av cookies: ',
        policy_link: 'Cookiepolicy',
        accept_all: 'Acceptera alla cookies',
        accept_neccesary: 'Acceptera endast nödvändiga cookies',
        settings: 'Cookie-inställningar',
        allow_all: 'Tillåt alla',
        settings_title: 'Hantera inställningar för samtycke',

        required: 'Absolut nödvändiga cookies',
        required_status: 'Alltid aktiv',
        required_details: 'Dessa cookies är nödvändiga för att webbplatsen ska fungera och kan inte stängas av i våra system. De är vanligtvis bara inställda som svar på åtgärder som du gjort som utgör en begäran om tjänster, till exempel inställning av dina personliga preferenser, inloggning eller fyllning av formulär. Du kan ställa in din webbläsare för att blockera eller varna dig om dessa cookies, men vissa delar av webbplatsen fungerar inte då. Dessa cookies lagrar inte någon personligt identifierbar information.',

        analyse: 'Statistik & Analys',
        analyse_details: 'Dessa cookies tillåter oss att räkna besök och trafikkällor så att vi kan mäta och förbättra prestandan på vår webbplats. De hjälper oss att veta vilka sidor som är mest och minst populära och se hur besökare flyttar runt på webbplatsen. All information som dessa cookies samlar är aggregerade och därför anonyma. Om du inte tillåter dessa cookies vet vi inte när du har besökt vår webbplats.',

        functional: 'Funktionella cookies',
        functional_details: 'Dessa cookies gör det möjligt för webbplatsen att tillhandahålla förbättrad funktionalitet och personlig anpassning. De kan fastställas av oss eller av tredjepartsleverantörer vars tjänster vi har lagt till på våra sidor. Om du inte tillåter dessa cookies kanske vissa eller alla av dessa tjänster inte fungerar som de ska.',

        targeted: 'Riktade cookies',
        targeted_details: 'Dessa cookies ställs in på vår webbplats av våra annonseringspartners. De kan användas av dessa företag för att skapa en profil av dina intressen och visa dig relevanta annonser på andra webbplatser. De lagrar inte direkt personlig information, men är baserade på unikt identifierande av din webbläsare och internet-enhet. Om du inte tillåter dessa cookies kommer du att erfara mindre riktade annonser.',

        accept_selected: 'Bekräfta mina val',
        warn: 'Avvisa alla',

        cookie_info: 'Information om cookies',
        cookie_list: 'Cookie-lista',
        see_cookies: 'Se cookies',

        first_party_cookies: 'Förstapartscookies',

        name: 'Namn',
        duration: 'Varaktighet',
        duration_session: 'Session',
        duration_second: 'Några sekunder',
        duration_year: '365 Dagar',
        duration_week: '7 Dagar',
        duration_season: '92 Dagar',
        duration_month: '31 Dagar',
        type: 'Typ',
        type_first_party: '1:a part',
        type_third_party: '3:e part',
        category: 'Kategori',
        category_required: 'Absolut nödvändiga cookies',
        description: 'Beskrivning'
    },

    Meta: {
        indexTitle: 'Shopit - Sveriges nya sökmotor för shopping och prisjämförelse',
        indexDescription: 'Shopit är Sveriges nya AI-baserade sökmotor för shopping och prisjämförelse. Jämför 10 miljoner produkter, i 90 000 kategorier, från mer än 5 000 butiker.\n',

        searchCategoryCompare: 'Jämför',
        searchCategoryCompareCounterSuffix: ' st',
        searchCategoryAtShopit: 'på Shopit.se',

        searchCollectionCompare: 'Jämför ',
        searchCollectionAtShopit: ' från mängder av tillverkare och butiker på Shopit.se',

        productIn: ' i ',
        productSuffix: ' | Shopit.se',
        productFrom: 'från ',

        storeRegisterTitle: 'Lägg till din butik | Shopit.se',

        howItWorksTitle: 'How it works | Shopit - Sökmotorn för shopping',
        contactTitle: 'Kontakta Shopit | Shopit - Sökmotorn för shopping',
        aboutTitle: 'Om | Shopit - Sökmotorn för shopping',
        cookiePolicyTitle: 'Cookie-policy | Shopit - Sökmotorn för shopping',
        personalPolicyTitle: 'Användar-policy | Shopit - Sökmotorn för shopping',
        disclaimerTitle: 'Ansvarsbegränsning | Shopit - Sökmotorn för shopping',
        addYourStoreTitle: 'Lägg till din butik | Shopit - Sökmotorn för shopping',

    }
};
