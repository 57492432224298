import React, {useEffect} from "react";
import {MetaTags} from "../../Components/App/Tags/Meta";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";

// ---

const ComponentTranslationKey = "Meta"

export default function ToShopitRoute(props: {}) {

    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={`service-page-container pt-16`}>

            <MetaTags title={getTranslation(`addYourStoreTitle`)}
                      canonicalPageUrl={`default`}
                      robots={undefined}/>

            <h1 className={`service-page-h1`}>Att synas som butik på Shopit</h1>
            <h2 className={`service-page-h2`}>Så här funkar det</h2>

            <div className={`service-page-block`}>
                Som butik skickar du en produktfeed med dina priser och produkter som Shopit sedan uppdaterar minst
                dagligen. Om du redan är med på andra sajter som t ex Prisjakt, Pricerunner eller Google Shopping kan du
                använda exakt samma typ av produktfeed även till oss. Därmed är det minimalt med arbete att lista dina
                produkter även på Shopit
            </div>

            <h3 className={`service-page-h3`}> Hur kommer min butik med på Shopit?</h3>
            <div className={`service-page-block`}>
                Du kan enkelt registrera dig här på vår hemsida. Vi kontrollerar dina butiksuppgifter, du godkänner vårt
                avtal och sedan visar vi dina produkter och priser på Shopit.
            </div>
            <div className={`service-page-block`}>
                <a className={`btn btn-green uppercase`} href={`https://business.shopit.com/store/register`} target={`_blank`}>
                    Lägg till din butik
                </a>
            </div>
            <div className={`service-page-block service-page-block-double-spacing`}>
                Är du intresserad av att din butik och produkter visas på Shopit? Då går det bra att kontakta vår
                försäljningsansvarige Henrik Kjellberg via telefon <br/>+46 (0)70 615 05 41 eller mejl <a
                href="mailto:sales@shopit.com" className={`text-shopit-green`}>sales@shopit.com</a>
            </div>

            <h3 className={`service-page-h3`}> Prova gratis i tre månader</h3>
            <div className={`service-page-block`}>
                Eftersom Shopit är en helt ny tjänst och det kommer att ta lite tid för oss att bygga upp trafik till
                vår
                sida, samt att det är också viktigt för oss att ni som butik ska kunna testa vår tjänst i lugn och ro,
                så
                får man som ny butik vara med i tre månader helt gratis.
            </div>
            <div className={`service-page-block`}>
                Det enda vi önskar är att du lägger en länk till oss någonstans på er hemsida. Det hjälper oss att
                snabbare
                bygga upp trafik till vår sida och därmed också kunna skicka vidare mer trafik till er sida.
            </div>

            <h3 className={`service-page-h3`}>Hur mycket kostar det sedan att vara med på Shopit?</h3>
            <div className={`service-page-block`}>
                När gratisperioden gått ut väljer ni själva om ni vill börja betala för att få alla era produkter
                länkade. Mellan 20-30% av era produkter kommer dock alltid att fortsätta att länkas gratis så länge ni
                har en länk till vår sida på er sajt.
            </div>
            <div className={`service-page-block`}>
                Som kund på Shopit betalar du bara för den trafik vi skickar vidare till er butik, och du bestämmer dina
                kostnader på Shopit själv. Alla butiker kan sätta en månadsbudget, vilket gör att du alltid har koll på
                dina
                utgifter.
            </div>
            <div className={`service-page-block`}>
                Vi har ett dynamiskt pris per klick (CPC) som gör att en besökare skickas till er sajt och priset
                varierar
                mellan olika produktkategorier. I jämförelse t ex de andra aktörerna på marknaden så ligger vi generellt
                30-50% billigare per klick. Kontakta oss gärna för att få mer specifik information om våra priser.
            </div>
            <div className={`service-page-block`}>
                Du kan avsluta när du vill med en dags uppsägningstid.
            </div>

            <h3 className={`service-page-h3`}>Adminsida för befintliga kunder</h3>
            <div className={`service-page-block`}>
                På er egen adminsida kan ni bland annat se information om er feed, produkter och er klickstatistik, samt
                ta
                fram olika typer av rapporter.
            </div>

            <h3 className={`service-page-h3`}>Vad är en produktfeed?</h3>
            <div className={`service-page-block`}>
                En produktfeed är en fil som lagras på din butiks server. Den innehåller all information om produkterna
                och
                priserna i din butik, snyggt och systematiskt ordnad i tabeller. Vi läser av filen flera gånger om
                dagen.
                Genom att hålla din information uppdaterad visar du alltid aktuella erbjudanden på Shopit.
            </div>

        </div>
    );
}
