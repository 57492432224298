// libraries
import React, {MouseEventHandler, useCallback, useEffect, useMemo} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";

// components
import Header from "./Layout/Header";
import NotificationPanel from "./Common/NotificationPanel";
import DelayApiCallMessage from "./Common/DelayApiCallMessage";
import PasswordResetNewPassword from "../Login/PasswordResetNewPassword";
import {
    getDefaultLang,
    InteractiveDelayType,
    triggerToggleCategories,
    triggerToggleFilters
} from "../../Store/Action/interactive";
import Footer from "./Layout/Footer";
import IndexRoute from "../../Routes/Index/indexRoute";
import LayoutRoute from "../../Routes/Service/Layout";
import TypographyRoute from "../../Routes/Service/Typography";
import LoadingOverlayPanel from "./Common/LoadingOverlayPanel";
import CompareRoute from "../../Routes/Compare";
import {useDispatch, useSelector} from "react-redux";
import ExternalRoute from "../../Routes/External/ExternalRoute";
import ModalVerification from "../ProductsGrid/Card_Product/ModalVerification";
import MenuContainer from "./Layout/MenuContainer";
import {getEventMenuClose, getEventMenuCloseCheck} from "../../Helpers/EventHelper";
import UserRoute from "../../Routes/User";
import UserProductListRoute from "../User/UserProductListRoute";
import CustomRoute from "../../Routes/CustomRoute";
import {makeSearchRouteContext, SearchRouteContext} from "../../Store/Context/SearchRouteContext";
import {emptyUrlParams, getSearchApiFromPageParams} from "../../Helpers/SearchHelper";
import {triggerPageChange} from "../../Store/Action/tracking";
import AboutRoute from "../../Routes/About/AboutRoute";
import ContactRoute from "../../Routes/About/ContactRoute";
import CookieRoute from "../../Routes/About/CookieRoute";
import DisclaimerRoute from "../../Routes/About/DisclaimerRouter";
import PersonalPolicyRoute from "../../Routes/About/PersonalPolicyRoute";
import HowItWorksRoute from "../../Routes/About/HowItWorksRoute";
import ToShopitRoute from "../../Routes/About/ToShopitRoute";
import {parseUrlToSearchParams} from "../../Helpers/UrlFormatter.Search";
import BusinessRegisterRedirectRoute from "../../Routes/About/BusinessRegisterRedirect";
import SearchSelectRoute from "../../Routes/SearchSelect";

function AppLayout() {
    // globals
    const dispatch = useDispatch();

    // extract
    const props = useSelector((state: IAppState) => ({
        visibleFilters: state.interactive.showFilters,
        visibleCategories: state.interactive.showCategories,
        visibleImageVerificationMenu: state.session.shophunter.haveVerificationMenu,

        activeVerifyProduct: state.session.shophunter?.activeProduct,
        activeVerifyProductVerifiedCategory:
            state.session.shophunter?.activeProduct
                ? state.session.shophunter.shophunterData?.verifiedProducts[state.session.shophunter?.activeProduct.id]?.verifiedCategoryId
                : undefined,
        lastVerifiedCategories: state.session.shophunter.shophunterData?.latestVerifyCategoryIds,

        imageVerifications: state.session.shophunter.shophunterData?.verifiedImages,
        userSessionId: state.session.userSessionId,
    }))

    // lifecycle
    const panelScroll = (e: any) => {
        window.dispatchEvent(getEventMenuCloseCheck(null))
    }

    useEffect(() => {
        document.addEventListener("scroll", panelScroll);

        return () => {
            document.removeEventListener("scroll", panelScroll);
        }
    }, []);

    // internal
    const panelCloseClick: MouseEventHandler<HTMLElement> = (e) => {
        if (props.visibleCategories || props.visibleFilters || props.visibleImageVerificationMenu) {

            window.dispatchEvent(getEventMenuCloseCheck(e.target as HTMLElement))

            let element: HTMLElement | null = e.target as HTMLElement;

            let closeSidebar = true;
            let closeImageVerificationMenu = true;

            while (element) {
                if (element.className.includes) {
                    if (element.className.includes("js-sidebar")) {
                        closeSidebar = false;
                        break;
                    }
                    if (element.className.includes("js-verification-menu")) {
                        closeImageVerificationMenu = false;
                        break;
                    }
                }
                element = element.parentElement;
            }

            if (closeSidebar) {
                if (props.visibleCategories) {
                    dispatch(triggerToggleCategories());
                }
                if (props.visibleFilters) {
                    dispatch(triggerToggleFilters());
                }
            }
            if (closeImageVerificationMenu) {
                window.dispatchEvent(getEventMenuClose())
            }
        }
    }

    // render
    return (
        <div className="app" onClick={panelCloseClick}>

            <div className="header fixed top-0 left-0 right-0 z-40">
                <Header/>
            </div>

            <div className="header h-32">{/*header compensation*/}</div>

            <div className="content relative">
                {/* TODO:
                        add '/retailer/:id'     (link on VendorsTable)
                */}

                {/*
                    make sure logger process urls right, if you change urls
                    search for "function getRoute(url: string)"
                */}
                <Routes>
                    <Route path="/" element={<IndexRoute/>}/>

                    <Route path="/search" element={<SearchSelectRoute/>}/>

                    {/*<Route path="/product/:product_id" element={<ProductRoute/>}/>*/}        {/* using other format now */}
                    <Route path="/compare/:product_ids" element={<CompareRoute/>}/>

                    <Route path="/user/:user_id" element={<UserRoute/>}/>
                    <Route path="/user/:user_id/saved_products/:type" element={<UserProductListRoute/>}/>

                    <Route path="/recover/accept" element={<PasswordResetNewPassword/>}/>
                    <Route path="/recover/reject"
                           element={<DelayApiCallMessage messageType={InteractiveDelayType.RecoverDecline}/>}/>
                    <Route path="/recover/check"
                           element={<DelayApiCallMessage messageType={InteractiveDelayType.RecoverCheck}/>}/>
                    <Route path="/register/confirm"
                           element={<DelayApiCallMessage messageType={InteractiveDelayType.RegisterConfirm}/>}/>

                    <Route path="/go-to-store" element={<ExternalRoute/>}/>

                    <Route path="/service/layout" element={<LayoutRoute/>}/>
                    <Route path="/service/typography" element={<TypographyRoute/>}/>

                    <Route path="/om" element={<AboutRoute/>}/>
                    <Route path="/about" element={<AboutRoute/>}/>
                    <Route path="/kontakt" element={<ContactRoute/>}/>
                    <Route path="/contact" element={<ContactRoute/>}/>
                    <Route path="/sa-funkar-det" element={<HowItWorksRoute/>}/>
                    <Route path="/how-it-works" element={<HowItWorksRoute/>}/>
                    <Route path="/cookie-policy" element={<CookieRoute/>}/>
                    <Route path="/anvandarpolicy" element={<PersonalPolicyRoute/>}/>
                    <Route path="/personal-policy" element={<PersonalPolicyRoute/>}/>
                    <Route path="/ansvarsbegransning" element={<DisclaimerRoute/>}/>
                    <Route path="/liability" element={<DisclaimerRoute/>}/>

                    <Route path="/lagg-till-din-butik" element={<ToShopitRoute/>}/>
                    <Route path="/add-your-store" element={<ToShopitRoute/>}/>

                    <Route path="/business/register" element={<BusinessRegisterRedirectRoute/>}/>

                    <Route path="*" element={<CustomRoute/>}/>
                </Routes>
            </div>

            <div className="footer mt-48"><Footer/></div>

            {props.activeVerifyProduct
                ? <ModalVerification isModalOpen={true}
                                     product={props.activeVerifyProduct}
                                     productCategoryIdVerified={props.activeVerifyProductVerifiedCategory}
                                     latestCategoryIds={props.lastVerifiedCategories}/>
                : null}

            <NotificationPanel/>
            <LoadingOverlayPanel/>
            <MenuContainer/>

        </div>
    );
}

export default function App() {

    // globals
    const location = useLocation()
    const dispatch = useDispatch()

    const lang = useSelector((state: IAppState) => state.config.lang) || getDefaultLang()
    const debug = useSelector((state: IAppState) => state.config.debug)

    // extract
    const searchRouteContext = useMemo(() => {
        const urlPageParams = parseUrlToSearchParams(location.pathname, location.search);
        const urlRequestKey = !emptyUrlParams(urlPageParams.bridgeParams) ? getSearchApiFromPageParams(urlPageParams) : undefined;
        return makeSearchRouteContext(urlRequestKey, lang, debug)
    }, [location.pathname, location.search, lang, debug])

    // tracker
    const pageUrl = useSelector((state: IAppState) => state.session.tracking.pageUrl)
    React.useEffect(() => {
        const newLocation = location.pathname + location.search
        if(newLocation != pageUrl){
            dispatch(triggerPageChange(newLocation))
        }
    }, [location])

    return <SearchRouteContext.Provider value={searchRouteContext}>
        <AppLayout/>
    </SearchRouteContext.Provider>
}

if (module.hot) {
    module.hot.accept()
}