import {Listbox} from "@headlessui/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {triggerCacheAccessProductWithCallback} from "../../../Store/Action/cache.product";
import {useDispatch} from "react-redux";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {useNavigate} from "react-router-dom";
import {buildProductUrl} from "../../../Helpers/UrlFormatter.Product";
import {triggerDebug} from "../../../Store/Action/interactive";

const ComponentTranslationKey = "ProductVariants"

export function ProductVariantSelect(props: { variants: IProductVariants, }) {

    // globals
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation(ComponentTranslationKey);

    // internal functions
    const getTranslation = (key: keyof TranslationSet[typeof ComponentTranslationKey]) => {
        return t(key)
    }

    const onProductVariantSelect = (value: string | number | undefined) =>{
        const productGtinId =
            typeof value == "number"
                ? value
                : typeof value == "string"
                    ? parseInt(value)
                    : undefined;

        if (productGtinId) {
            dispatch(triggerCacheAccessProductWithCallback(productGtinId, () => {
                const [productUrl, isValidLink] = buildProductUrl(productGtinId, undefined, undefined)

                if (!isValidLink) {
                    console.log("Product link error");
                    console.log([undefined, undefined])
                    const trace = new Error().stack
                    console.log(trace)
                    dispatch(triggerDebug("Product link error (console)", trace))
                }

                navigate({
                    pathname: productUrl
                })
            }))
        }
    }

    return <div>
        {props.variants.variant_attributes.map((attribute, index) => {
            let selectedOption = attribute.attribute_options.find((option) => option.selected);

            return <div key={`variant-` + index}>
                <div className="product-variants-header">
                    {attribute.attribute_name == "88"
                        ? getTranslation('select_size')
                        : attribute.attribute_name == "89"
                            ? getTranslation('select_color')
                            : (getTranslation('select_variant') + ': ' + attribute.attribute_name)}
                </div>

                <Listbox value={selectedOption?.merge_product_gtin_id} onChange={onProductVariantSelect}>
                    <div className="product-variants-options-wrapper">
                        <Listbox.Button className="btn btn-grey">
                            <div className="inline-flex w-36 justify-between">
                                <div>{selectedOption?.option_name}</div>
                                <div>&#8597;</div>
                            </div>
                        </Listbox.Button>

                        <Listbox.Options
                            className="product-variants-options-list z-10">
                            {attribute.attribute_options.map((option, index) => (
                                <Listbox.Option key={`option-` + index}
                                                className={({active}) => `product-variants-option ${active ? 'bg-shopit-green-light' : ''}`}
                                                value={option.merge_product_id}>
                                    {({selected}) => (
                                        <div className="product-variants-option-details">
                                            <div className="product-variants-option-details-selected-market">
                                                {selected ? <span>&#10003;</span> : ''}
                                            </div>
                                            <div className="inline-block">{option.option_name}</div>
                                        </div>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </div>
                </Listbox>

            </div>
        })}
    </div>
}