import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {MetaTags} from "../../Components/App/Tags/Meta";

// ---

const ComponentTranslationKey = "Meta"

export default function AboutRoute(props: {}) {

    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={`service-page-container pt-16`}>

            <MetaTags title={getTranslation('aboutTitle')}
                      canonicalPageUrl={`default`}
                      robots={undefined}/>

            <h1 className={`service-page-h1`}>Om Shopit</h1>

            <p className={`service-page-block`}>
                Shopit är en shoppingsökmotor för konsumenter som hjälper dig att göra smartare köp. Vi har ett av
                världens bästa filter som låter dig hitta produkter som passar dina behov bland ett stort utbud. Vi
                visar dig också vilka produkter som är mest populära bland andra konsumenter.
            </p>

            <p className={`service-page-block`}>
                Med Shopit kan du också jämföra priser från alla nätbutiker vi vet om. Vi garanterar att vi alltid
                visar det billigaste priset först på våra produktsidor, eftersom vi inte låter butikerna betala för
                att hamna högre upp i listan.
            </p>

            <p className={`service-page-block`}>
                Som gratis medlem hos Shopit får du fler fördelar. Du kan spara produkter som du är intresserad av
                eller som du redan har köpt.
            </p>

            <p className={`service-page-block`}>
                Shopit är gratis för dig som konsument och vi har inget intresse av att få dig att köpa mer än du
                behöver. Vårt mål är att vägleda dig till rätt köp när du är redo att handla.
            </p>

            <p className={`service-page-block`}>
                Shopit får inkomster genom att ta en liten avgift från butikerna när du klickar dig vidare till dem.
                Men vi visar alltid alla priser vi känner till och vi sorterar dem efter pris.
            </p>

            <p className={`service-page-block`}>
                Välkommen att använda Shopit för att göra smartare köp!
            </p>
        </div>
    );
}
