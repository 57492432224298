import React, {CSSProperties, Dispatch} from "react";
import {getImageUrl} from "../../../Helpers/ImageHelper";
import SearchHighlight from "../SearchHighlight";
import SearchRouteLink from "../../App/Links/SearchRouteLink";
import ProductRouteLink from "../../App/Links/ProductRouteLink";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {getDefaultLang, triggerDebug} from "../../../Store/Action/interactive";
import {useDispatch, useSelector} from "react-redux";
import {formatNumber} from "../../../Helpers/StringFormatter.Number";
import {replaceSpecialUnicodeCharacters} from "../../../Helpers/StringFormatter.Text";
import {formatPrice} from "../../../Helpers/StringFormatter.Price";
import {buildProductUrl} from "../../../Helpers/UrlFormatter.Product";
import {getPageParamsFromSearchUrl} from "../../../Helpers/SearchHelper";
import {buildSearchUrl} from "../../../Helpers/UrlFormatter.Search";

type SearchInputResultProps = {
    searchString: string,
    saytResult?: ISaytItems,
    isLoading: boolean,
    isError: boolean,
}

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "SearchAsYouType"

export default function SearchInputResult(props: SearchInputResultProps) {

    const dispatch = useDispatch();

    // globals
    const {t} = useTranslation(ComponentTranslationKey)
    const lang = useSelector((state: IAppState) => state.config.lang ? state.config.lang : getDefaultLang())
    const isDebug = useSelector((state: IAppState) => state.config.debug && !!state.session.user)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    // render
    const renderProduct = function (product: SaytUrlData & SaytProduct, key: string, domKey: string) {
        let price = null
        if (product.price && product.price.price_min_incl_vat) {
            let currency = (product.price.price_currency + '').toLowerCase()
            if (currency === 'sek') {
                currency = 'kr'
            }
            price = <span>
                        {`${formatPrice(product.price.price_min_incl_vat + '', 0)} ${currency}`}
                    </span>
        } else if (product.unbuyablePrice && product.unbuyablePrice.price_min_incl_vat) {
            let currency = (product.unbuyablePrice.price_currency + '').toLowerCase()
            if (currency === 'sek') {
                currency = 'kr'
            }
            price = <span className="sayt-product-price-secondary">
                        {`${formatPrice(product.unbuyablePrice.price_min_incl_vat + '', 0)} ${currency}`}
                    </span>
        }

        const productName = product && product.name
            ? (isDebug
                ? product.name
                : replaceSpecialUnicodeCharacters(product.name))
            : undefined;

        const [productUrl, isValidLink] = product.gtinId
            ? buildProductUrl(product.gtinId, product.name, product.category?.name)
            : buildProductUrl(product.id, product.name, product.category?.name);
        if (!isValidLink) {
            console.log("Product link error");
            console.log([product.name, product.category?.name])
            const trace = new Error().stack
            console.log(trace)
            dispatch(triggerDebug("Product link error (console)", trace))
        }

        // origin link had a form {pathname: "/product", search: "id=" + product.id}
        return (
            <ProductRouteLink
                className="sayt-product"
                key={domKey}
                to={{
                    pathname: productUrl
                }}
                onMouseDown={(e) => {
                    e.currentTarget.click();  // fix for blur-click problem
                }}>

                <div className="sayt-product-image">
                    {product.imageId ?
                        <img src={getImageUrl(product.imageId, 'xs')}/> :
                        <img src={getImageUrl(0, 'xs')}/>}
                </div>
                <div className="sayt-product-name">
                    <div className="sayt-product-product-name" title={productName}>
                        <SearchHighlight text={productName} highlight={props.searchString}/>
                        {/*{product.name}*/}
                    </div>
                    <div className={`sayt-category`}>
                        <div>
                            {product.categoryNotListed
                                ? <div className={`sayt-product-uncategorized`}>{getTranslation('uncategorized')}</div>
                                : product.category ?
                                    <div className="sayt-product-category-name">
                                        <SearchHighlight text={product.category.name} highlight={props.searchString}/>
                                    </div> : ''}
                        </div>
                        <div className="sayt-product-price">
                            {price}
                        </div>
                    </div>
                </div>

            </ProductRouteLink>
        );
    }

    const renderNonProduct = function (item:
                                           SaytGroupItem_Product |
                                           SaytGroupItem_Category |
                                           SaytGroupItem_CategoryPath |
                                           SaytGroupItem_Brand |
                                           SaytGroupItem_Family |
                                           SaytGroupItem_ProductModel |
                                           SaytGroupItem_AccessoryAndParts |
                                           SaytGroupItem_AccessoryAndPartsAll |
                                           SaytGroupItem_FreeText, key: string, groupDomKey: string) {

        let linkText: React.ReactElement[] = [];
        let linkProperties: CSSProperties = {}
        let counts: React.ReactElement | undefined

        const urlSeparator = item.url.indexOf('?');
        let linkPathname: string = '';
        let linkSearch: string | undefined = undefined;

        const defaultLink = (): [string, string | undefined] => {
            return urlSeparator !== -1 ? [item.url.substring(0, urlSeparator), item.url.substring(urlSeparator)] : [item.url, undefined]
        }

        switch (item.type) {

            case "PRODUCT": {
                const productItem = item as SaytGroupItem_Product

                // link build
                if (productItem.gtinId) {
                    const [productUrl, isValidLink] = buildProductUrl(productItem.gtinId, productItem.productName, undefined)

                    linkPathname = productUrl;
                    if (!isValidLink) {
                        console.log("Product link error");
                        console.log([productItem.productName, undefined])
                        const trace = new Error().stack
                        console.log(trace)
                        dispatch(triggerDebug("Product link error (console)", trace))
                    }

                } else {
                    [linkPathname, linkSearch] = defaultLink()
                }

                // render
                linkText.push(<span className={`product`} key={key}>{productItem.productName}</span>)
                break;
            }

            case "CATEGORY": {
                const categoryItem = item as SaytGroupItem_Category

                // link build
                [linkPathname, linkSearch] = getCategoryLinkData(categoryItem, dispatch)

                // render
                linkText = getCategoryHtml(key, categoryItem, props.searchString, getTranslation)
                break;
            }

            case "CATEGORY_PATH": {
                const categoryItem = item as SaytGroupItem_CategoryPath

                // link build
                [linkPathname, linkSearch] = getCategoryPathLinkData(categoryItem, dispatch)

                //render
                linkText.push(getCategoryPathHtml(key, categoryItem))
                break;
            }

            case "BRAND": {
                const brandItem = item as SaytGroupItem_Brand

                // link build
                [linkPathname, linkSearch] = getBrandLinkData(brandItem, dispatch)

                // render
                linkText.push(getBrandHtml(key, brandItem, props.searchString, getTranslation))
                break;
            }

            case "FAMILY": {
                const brandItem = item as SaytGroupItem_Family

                // link build
                [linkPathname, linkSearch] = getFamilyLinkData(brandItem, dispatch)

                // render
                linkText.push(getFamilyHtml(key, brandItem, props.searchString, getTranslation));
                break;
            }

            case "PRODUCT_MODEL": {
                const unpItem = item as SaytGroupItem_ProductModel

                // build link
                [linkPathname, linkSearch] = getProductModelLinkData(unpItem, dispatch)

                // render
                linkText.push(getProductModelHtml(key, unpItem, props.searchString, getTranslation));
                break;
            }

            case "ACCESSORY_PARTS": {
                const partsItem = item as SaytGroupItem_AccessoryAndParts

                // build link
                [linkPathname, linkSearch] = getAccessoryPartsLinkData(partsItem, dispatch)

                // render
                linkText.push(getAccessoryPartsHtml(key, partsItem, props.searchString, getTranslation));
                break;
            }

            case "ACCESSORY_PARTS_ALL": {
                const partsItem = item as SaytGroupItem_AccessoryAndPartsAll

                // build link
                [linkPathname, linkSearch] = getAccessoryAndPartsAllLinkData(partsItem, dispatch)

                // render
                linkText.push(getAccessoryPartsAllHtml(key, partsItem, props.searchString, getTranslation));
                break;
            }

            case "FREETEXT": {
                const freeItem = item as SaytGroupItem_FreeText
                // link build
                [linkPathname, linkSearch] = getFreetextLinkData(freeItem, dispatch)

                // render
                linkText.push(getFreeTextHtml(key, freeItem));
                break;
            }
            default:
                break;
        }

        if (linkText.length === 0) {
            return null
        }

        return (
            <SearchRouteLink
                className="sayt-non-product" key={`${key}.l`}
                style={linkProperties}
                to={{
                    pathname: linkPathname,
                    search: linkSearch
                }}
                onMouseDown={(e) => {
                    e.currentTarget.click();  // fix for blur-click problem
                }}>
                <div className="sayt-non-product-text">{linkText}</div>
                {counts}
            </SearchRouteLink>
        );
    }

    if (props.isLoading) {
        return <div className="search-as-you-type-result-list">
            Loading...
        </div>
    }

    if (props.isError) {
        return <div className="search-as-you-type-result-list">
            {getTranslation('error')}
        </div>
    }

    if (!props.saytResult) {
        return null;
    }

    const keyNames: { [key: string]: string } = {
        'product': getTranslation('product'),
        'productSecondary': getTranslation('productSecondary'),
        'category': getTranslation('category'),
        'manufacturer': getTranslation('manufacturer'),
        'branding': getTranslation('branding'),
        'vendor': getTranslation('vendor'),
        'code': getTranslation('code'),
        'uniqueNamePath': getTranslation('uniqueNamePath'),
        'relatedUniqueNamePathCollection': getTranslation('relatedUniqueNamePathCollection'),
        'other': getTranslation('other'),
        'unknown': getTranslation('unknown'),
    }

    let productRender = [];
    if (props.saytResult) {
        for (const [key, valueRaw] of Object.entries(props.saytResult.products)) {
            const itemSet = valueRaw as SaytProduct[];
            if (itemSet.length > 0) {
                productRender.push(
                    <div className="sayt-header" key={`${key}.header`}>
                        {keyNames[key]}
                    </div>);

                itemSet.forEach((value, valueKey) => {
                    productRender.push(renderProduct(value as SaytUrlData & SaytProduct, key, `${key}.${valueKey}`));
                });
            }
        }
    }

    let nonProductRender: (null | JSX.Element)[] = [];

    if (props.saytResult) {

        props.saytResult.nonProducts.forEach((group, key) => {
            const header =
                group.type == "CATEGORY" ? getTranslation('category')
                    : group.type == "PRODUCT_MODEL" ? getTranslation('uniqueNamePath')
                        : group.type == "ACCESSORIES_PARTS" ? getTranslation('relatedUniqueNamePathCollection')
                            : group.type == "MANUFACTURERS" ? getTranslation('manufacturer')
                                : group.type == "BRANDS" ? getTranslation('branding')
                                    : group.type == "STORES" ? getTranslation('vendor')
                                        : group.type == "FREETEXT" ? getTranslation('other')
                                            : group.type == "UNKNOWN" ? getTranslation('unknown')
                                                : getTranslation('unknown')

            nonProductRender.push(
                <div className="sayt-header" key={`${key}.header`}>{header}</div>);

            group.items.forEach((value, valueKey) => {
                nonProductRender.push(renderNonProduct(value, `${key.toString()}.${valueKey}`, `${key}.group`));
            });
        })
    }

    if (nonProductRender.length == 0 && productRender.length == 0) {
        return <div className="search-as-you-type-result-list text-gray-500">
            {getTranslation('no_result')}
        </div>
    }

    return (
        <div className="sayt-result-list">
            <div className="sayt-result-non-product-list">
                {nonProductRender}

                {isDebug && props.saytResult && props.saytResult.logData.timer
                    ? <div className={`sayt-section-timer`}>
                        ({formatNumber(Math.round(props.saytResult.logData.timer * 1000) / 1000, 3, 'en')} {getTranslation('seconds')})
                    </div>
                    : null}
            </div>
            <div className="sayt-result-product-list">
                {productRender}

                {isDebug && props.saytResult && props.saytResult.logData.timerTitleMatch
                    ? <div className={`sayt-section-timer`}>
                        ({formatNumber(Math.round(props.saytResult.logData.timerTitleMatch * 1000) / 1000, 3, 'en')} {getTranslation('seconds')})
                    </div>
                    : null}
            </div>
        </div>
    );
}

export function getCategoryLinkData(categoryItem: SaytGroupItem_Category, dispatch: Dispatch<any>) {
    const urlParams = getPageParamsFromSearchUrl(categoryItem.url);
    const [searchPathname, searchSearch, isValidLink] = buildSearchUrl(urlParams, {
        category: {[categoryItem.categoryId]: categoryItem.categoryName},
        brand: {},
        manufacturer: {},
        family: {},
        unp: {},
    })
    if (!isValidLink) {
        console.log("Search link error");
        console.log(searchPathname, searchSearch, urlParams, {
            category: {[categoryItem.categoryId]: categoryItem.categoryName},
            brand: {},
            manufacturer: {},
            family: {},
            unp: {},
        })
        const trace = new Error().stack
        console.log(trace)
        dispatch(triggerDebug("Search link error (console)", trace))
    }
    return [searchPathname, searchSearch]
}

export function getCategoryPathLinkData(categoryItem: SaytGroupItem_CategoryPath, dispatch: Dispatch<any>) {
    const urlParams = getPageParamsFromSearchUrl(categoryItem.url);
    const [searchPathname, searchSearch, isValidLink] = buildSearchUrl(urlParams, {
        category: {[categoryItem.categoryId]: categoryItem.categoryName},
        brand: {},
        manufacturer: {},
        family: {},
        unp: {},
    })
    if (!isValidLink) {
        console.log("Search link error");
        console.log(searchPathname, searchSearch, urlParams, {
            category: {[categoryItem.categoryId]: categoryItem.categoryName},
            brand: {},
            manufacturer: {},
            family: {},
            unp: {},
        })
        const trace = new Error().stack
        console.log(trace)
        dispatch(triggerDebug("Search link error (console)", trace))
    }
    return [searchPathname, searchSearch]
}

export function getBrandLinkData(brandItem: SaytGroupItem_Brand, dispatch: Dispatch<any>) {
    const urlParams = getPageParamsFromSearchUrl(brandItem.url);
    const [searchPathname, searchSearch, isValidLink] = buildSearchUrl(urlParams, {
        category: {},
        brand: brandItem.isManufacturer ? {} : {[brandItem.brandId]: brandItem.brandName},
        manufacturer: brandItem.isManufacturer ? {[brandItem.brandId]: brandItem.brandName} : {},
        family: {},
        unp: {},
    })
    if (!isValidLink) {
        console.log("Search link error");
        console.log(searchPathname, searchSearch, urlParams, {
            category: {},
            brand: brandItem.isManufacturer ? {} : {[brandItem.brandId]: brandItem.brandName},
            manufacturer: brandItem.isManufacturer ? {[brandItem.brandId]: brandItem.brandName} : {},
            family: {},
            unp: {},
        })
        const trace = new Error().stack
        console.log(trace)
        dispatch(triggerDebug("Search link error (console)", trace))
    }
    return [searchPathname, searchSearch]
}

export function getFamilyLinkData(brandItem: SaytGroupItem_Family, dispatch: Dispatch<any>) {
    const urlParams = getPageParamsFromSearchUrl(brandItem.url);
    const [searchPathname, searchSearch, isValidLink] = buildSearchUrl(urlParams, {
        category: {},
        brand: (!brandItem.isManufacturerFamily && brandItem.brandId ? {[brandItem.brandId]: brandItem.brandName} : {}),
        manufacturer: (brandItem.isManufacturerFamily && brandItem.brandId ? {[brandItem.brandId]: brandItem.brandName} : {}),
        family: (brandItem.familyId ? {[brandItem.familyId]: brandItem.familyName} : {}),
        unp: {},
    })
    if (!isValidLink) {
        console.log("Search link error");
        console.log(searchPathname, searchSearch, urlParams, {
            category: {},
            brand: (!brandItem.isManufacturerFamily && brandItem.brandId ? {[brandItem.brandId]: brandItem.brandName} : {}),
            manufacturer: (brandItem.isManufacturerFamily && brandItem.brandId ? {[brandItem.brandId]: brandItem.brandName} : {}),
            family: (brandItem.familyId ? {[brandItem.familyId]: brandItem.familyName} : {}),
            unp: {},
        })
        const trace = new Error().stack
        console.log(trace)
        dispatch(triggerDebug("Search link error (console)", trace))
    }
    return [searchPathname, searchSearch]
}

export function getProductModelLinkData(unpItem: SaytGroupItem_ProductModel, dispatch: Dispatch<any>) {
    const urlParams = getPageParamsFromSearchUrl(unpItem.url);
    const [searchPathname, searchSearch, isValidLink] = buildSearchUrl(urlParams, {
        category: unpItem.categoryId ? {[unpItem.categoryId]: unpItem.categoryName} : {},
        brand: {},
        manufacturer: {},
        family: {},
        unp: {},
    })
    if (!isValidLink) {
        console.log("Search link error");
        console.log(searchPathname, searchSearch, urlParams, {
            category: unpItem.categoryId ? {[unpItem.categoryId]: unpItem.categoryName} : {},
            brand: {},
            manufacturer: {},
            family: {},
            unp: {},
        })
        const trace = new Error().stack
        console.log(trace)
        dispatch(triggerDebug("Search link error (console)", trace))
    }
    return [searchPathname, searchSearch]
}

export function getAccessoryPartsLinkData(partsItem: SaytGroupItem_AccessoryAndParts, dispatch: Dispatch<any>) {
    const urlParams = getPageParamsFromSearchUrl(partsItem.url);
    const [searchPathname, searchSearch, isValidLink] = buildSearchUrl(urlParams, {
        category: {},
        brand: {},
        manufacturer: {},
        family: {},
        unp: {},
    })
    if (!isValidLink) {
        console.log("Search link error");
        console.log(searchPathname, searchSearch, urlParams, {
            category: {},
            brand: {},
            manufacturer: {},
            family: {},
            unp: {},
        })
        const trace = new Error().stack
        console.log(trace)
        dispatch(triggerDebug("Search link error (console)", trace))
    }
    return [searchPathname, searchSearch]
}

export function getAccessoryAndPartsAllLinkData(partsItem: SaytGroupItem_AccessoryAndPartsAll, dispatch: Dispatch<any>) {
    const urlParams = getPageParamsFromSearchUrl(partsItem.url);
    const [searchPathname, searchSearch, isValidLink] = buildSearchUrl(urlParams, {
        category: {},
        brand: {},
        manufacturer: {},
        family: {},
        unp: {[partsItem.unpId]: partsItem.text},
    })
    if (!isValidLink) {
        console.log("Search link error");
        console.log(searchPathname, searchSearch, urlParams, {
            category: {},
            brand: {},
            manufacturer: {},
            family: {},
            unp: {[partsItem.unpId]: partsItem.text},
        })
        const trace = new Error().stack
        console.log(trace)
        dispatch(triggerDebug("Search link error (console)", trace))
    }
    return [searchPathname, searchSearch]
}

export function getFreetextLinkData(feedItem: SaytGroupItem_FreeText, dispatch: Dispatch<any>) {
    const urlParams = getPageParamsFromSearchUrl(feedItem.url);
    const [searchPathname, searchSearch, isValidLink] = buildSearchUrl(urlParams, {
        category: {},
        brand: {},
        manufacturer: {},
        family: {},
        unp: {},
    })
    if (!isValidLink) {
        console.log("Search link error");
        console.log(searchPathname, searchSearch, urlParams, {
            category: {},
            brand: {},
            manufacturer: {},
            family: {},
            unp: {},
        })
        const trace = new Error().stack
        console.log(trace)
        dispatch(triggerDebug("Search link error (console)", trace))
    }
    return [searchPathname, searchSearch]
}

export function getCategoryHtml(key: string, categoryItem: SaytGroupItem_Category, searchString: string, getTranslation: any) {
    let elements: React.ReactElement[] = [];
    elements.push(<span key={`${key}.n`}
                        className={`${categoryItem.isCollection ? `collection` : `category`} ${categoryItem.isExtra ? `extra` : ``}`}
                        style={{paddingLeft: categoryItem.level * 8}}>
                     <SearchHighlight text={categoryItem.categoryName} highlight={searchString}/>
                </span>)
    if (categoryItem.categorySynonym) {
        elements.push(<span key={`${key}.syn`}
                            className={`${categoryItem.isCollection ? `sayt-matched-text-italic` : ``} sayt-matched-text-alternative`}>
                        <SearchHighlight text={` (${categoryItem.categorySynonym})`} highlight={searchString}/>
                    </span>)
    }
    if (categoryItem.manufacturerName) {
        elements.push(
            <div className={`category-manufacturer`} key={`${key}.man`}>
                <span className={`from`}> {getTranslation("from")} </span>
                <span className={`manufacturer`}>
                                <SearchHighlight text={` ${categoryItem.manufacturerName}`}
                                                 highlight={searchString}/>
                            </span>
            </div>
        )
    }
    if (categoryItem.brandingName) {
        elements.push(
            <div className={`category-branding`} key={`${key}.br`}>
                <span className={`from`}> {getTranslation("from")} </span>
                <span className={`branding`}>
                     <SearchHighlight text={` ${categoryItem.brandingName}`} highlight={searchString}/>
                </span>
            </div>
        )
    }
    if (categoryItem.familyName) {
        elements.push(<span className={`manufacturer family`} key={`${key}.fam`}>
                        <SearchHighlight text={` ${categoryItem.familyName}`} highlight={searchString}/>
                    </span>)
    }
    if (categoryItem.optionNames && categoryItem.optionNames.length) {
        categoryItem.optionNames.forEach((a, index) => {
            elements.push(<span className={`option`} key={`${key}.o.${index}`}>
                        <SearchHighlight text={`${index > 0 ? ', ' : ' '}${a}`} highlight={searchString}/>
                    </span>)
        })
    }
    return elements
}

export function getCategoryPathHtml(key: string, categoryItem: SaytGroupItem_CategoryPath) {
    return <span key={`${key}.n`}
                 className={`${categoryItem.isCollection ? `collection` : `category`} extra`}
                 style={{paddingLeft: categoryItem.level * 8}}>{categoryItem.categoryName}</span>
}

export function getBrandHtml(key: string, brandItem: SaytGroupItem_Brand, searchString: string, getTranslation: any) {
    return <div className="left-right-pair" key={`${key}.a`}>
        <div style={{paddingLeft: brandItem.level * 8}}>
                            <span className={`${brandItem.isManufacturer ? `manufacturer` : `branding`}`}>
                                <SearchHighlight text={brandItem.brandName} highlight={searchString}/>
                            </span>
            {brandItem.parentBrands && brandItem.parentBrands.length ?
                <span className={`sayt-matched-text`}>
                                    <span key={`s`}> (</span>
                    {brandItem.parentBrands.map((a, index) => (
                        <span key={index}>
                            <SearchHighlight text={`${index == 0 ? '' : ' '}${a}`} highlight={searchString}/>
                        </span>))}
                    <span key={`e`}>)</span>
                </span>
                : null}
            {brandItem.optionNames && brandItem.optionNames.length ?
                <>
                    {brandItem.optionNames.map((a, index) => (
                        <span className={`option`} key={index}>
                            <SearchHighlight text={`${index > 0 ? ', ' : ' '}${a}`} highlight={searchString}/>
                        </span>))}
                </>
                : null}
        </div>
        <div>
            {brandItem.categoryName || brandItem.categorySynonym ?
                <div>
                    <span className={`left-right-pair-i`}> {getTranslation("in")} </span>
                    <span className={'left-right-pair-category'}>
                        {brandItem.categoryName ?
                            <SearchHighlight text={brandItem.categoryName} highlight={searchString}/>
                            : null}
                        {brandItem.categorySynonym ?
                            <span
                                className={`sayt-matched-text`}>
                                <SearchHighlight text={` (${brandItem.categorySynonym})`} highlight={searchString}/>
                            </span>
                            : null}
                    </span>
                </div>
                : null
            }
        </div>
    </div>
}

export function getProductModelHtml(key: string, unpItem: SaytGroupItem_ProductModel, searchString: string, getTranslation: any) {
    return <div className="unique-name-path" key={`${key}.a`}>
        <div>
            <span>
                <SearchHighlight text={unpItem.text} highlight={searchString}/>
                {unpItem.optionNames && unpItem.optionNames.length ?
                    <>
                        {unpItem.optionNames.map((a, index) => (
                            <span className={`option small-option`} key={index}>
                            <SearchHighlight text={`${index > 0 ? ', ' : ' '}${a}`} highlight={searchString}/>
                        </span>))}
                    </>
                    : null}
            </span>
        </div>
        <div>
            <span className={`unique-name-path-i`}> {getTranslation("in")} </span>
            <span className={'unique-name-path-category'}>
                                <SearchHighlight text={unpItem.categoryName} highlight={searchString}/>
                {unpItem.categorySynonym ?
                    <span
                        className={`sayt-matched-text-alternative`}>
                            <SearchHighlight text={` (${unpItem.categorySynonym})`} highlight={searchString}/>
                        </span>
                    : null}
                </span>
        </div>
    </div>
}

export function getAccessoryPartsHtml(key: string, partsItem: SaytGroupItem_AccessoryAndParts, searchString: string, getTranslation: any) {
    return <div className="unique-name-path" key={`${key}.a`}>
        <div>
            <span>
                <SearchHighlight text={partsItem.text} highlight={searchString}/>
            </span>
        </div>
        <div>
            <span className={`unique-name-path-i`}> {getTranslation("in")} </span>
            <span className={'unique-name-path-category'}>
            <SearchHighlight text={partsItem.categoryName} highlight={searchString}/>
                {partsItem.categorySynonym ?
                    <span className={`${partsItem.isCollection ? `sayt-matched-text-italic` : ``} sayt-matched-text`}>
                        <SearchHighlight text={` (${partsItem.categorySynonym})`} highlight={searchString}/>
                    </span>
                    : null}
                {partsItem.optionNames && partsItem.optionNames.length ?
                    <>
                        {partsItem.optionNames.map((a, index) => (
                            <span className={`option`} key={index}>
                                <SearchHighlight text={`${index > 0 ? ', ' : ' '}${a}`} highlight={searchString}/>
                            </span>))}
                    </>
                    : null}
            </span>
        </div>
    </div>
}

export function getAccessoryPartsAllHtml(key: string, partsItem: SaytGroupItem_AccessoryAndPartsAll, searchString: string, getTranslation: any) {
    return <div className="unique-name-path" key={`${key}.a`}>
        <div>
            <span>
                <SearchHighlight text={partsItem.text} highlight={searchString}/>
            </span>
        </div>
        <div>
            <span className={`unique-name-path-i`}> {getTranslation("in")} </span>
            <span
                className={'unique-name-path-category unique-name-path-category-gray'}>{getTranslation("relatedUniqueNamePathCollection")}</span>
            {partsItem.optionNames && partsItem.optionNames.length ?
                <>
                    {partsItem.optionNames.map((a, index) => (
                        <span className={`option small-option`} key={index}>
                        <SearchHighlight text={`${index > 0 ? ', ' : ' '}${a}`} highlight={searchString}/>
                    </span>))}
                </>
                : null}
        </div>
    </div>
}

export function getFamilyHtml(key: string, brandItem: SaytGroupItem_Family, searchString: string, getTranslation: any) {
    return <div className="left-right-pair" key={`${key}.a`}>
        <div style={{paddingLeft: brandItem.level * 8}}>
            {brandItem.brandName ?
                <span className={`${brandItem.isManufacturerFamily ? `manufacturer` : `branding`}`}>
                    <SearchHighlight text={`${brandItem.brandName} `} highlight={searchString}/>
                </span>
                : null}
            <span className={`${brandItem.isManufacturerFamily ? `manufacturer` : `branding`} family`}>
                <SearchHighlight text={brandItem.familyName} highlight={searchString}/>
            </span>
            {brandItem.optionNames && brandItem.optionNames.length ?
                <>
                    {brandItem.optionNames.map((a, index) => (
                        <span className={`option`} key={index}>
                            <SearchHighlight text={`${index > 0 ? ', ' : ' '}${a}`} highlight={searchString}/>
                        </span>))}
                </>
                : null}
        </div>
        <div>
            {brandItem.categoryName || brandItem.categorySynonym ?
                <div>
                    <span className={`left-right-pair-i`}> {getTranslation("in")} </span>
                    <span className={'left-right-pair-category'}>
                        {brandItem.categoryName ?
                            <SearchHighlight text={brandItem.categoryName} highlight={searchString}/>
                            : null
                        }
                        {brandItem.categorySynonym ?
                            <span className={`sayt-matched-text`}>
                                <SearchHighlight text={` (${brandItem.categorySynonym})`} highlight={searchString}/>
                            </span>
                            : null
                        }
                    </span>
                </div>
                : null
            }
        </div>
    </div>
}

export function getFreeTextHtml(key: string, freeItem: SaytGroupItem_FreeText) {
    return <span className={`freetext`} key={`${key}.n`}>{freeItem.text}</span>
}