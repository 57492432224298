import {
    CacheSaveProductFoundAction,
    CacheSaveProductNotFoundAction,
    CacheSaveProductLoadingAction,
    CACHE_SAVE_PRODUCT_FOUND,
    CACHE_SAVE_PRODUCT_NOT_FOUND,
    CACHE_SAVE_PRODUCT_LOADING,
    getProductCacheKey
} from "../Action/cache.product";
import {
    CACHE_SAVE_SEARCH_FOUND,
    CACHE_SAVE_SEARCH_LOADING,
    CACHE_SAVE_SEARCH_NOT_FOUND, CACHE_SAVE_SEARCH_NOT_RESPOND,
    CacheSaveSearchFoundAction,
    CacheSaveSearchLoadingAction,
    CacheSaveSearchNotFoundAction, CacheSaveSearchNotRespondAction,
    getSearchCacheKey
} from "../Action/cache.search";
import {
    getCategoryStructureCacheKey,
    RESPONSE_CATEGORY_STRUCTURE_NOT_FOUND,
    RESPONSE_CATEGORY_STRUCTURE_SUCCESS,
    ResponseCategoryStructureAction
} from "../Action/cache.categoryTree";
import {
    LOAD_USER_SUCCESS,
    UserLoadSuccessAction
} from "../Action/cache.user";
import {
    getCategoryCacheKey
} from "../StoreHelper";
import {
    LOAD_PRODUCT_COMPARE_PREVIEW_FAILED, LOAD_PRODUCT_COMPARE_PREVIEW_LOADING,
    LOAD_PRODUCT_COMPARE_PREVIEW_SUCCESS, LOAD_PRODUCT_LIKE_PREVIEW_FAILED, LOAD_PRODUCT_LIKE_PREVIEW_LOADING,
    LOAD_PRODUCT_LIKE_PREVIEW_SUCCESS, LOAD_PRODUCT_OWN_PREVIEW_FAILED, LOAD_PRODUCT_OWN_PREVIEW_LOADING,
    LOAD_PRODUCT_OWN_PREVIEW_SUCCESS, ProductPreviewLoadFailedAction,
    ProductPreviewLoadSuccessAction
} from "../Action/session.product";

export const cacheInitialState: IAppCacheState = {
    categoryTree: {},
    users: {},
    category: {},
    search: {},
    product: {},
    personalProducts: {
        likeProductsKey: undefined,
        likeProductsPreview: {cacheState: CacheState.Unknown, object: undefined},
        ownProductsKey: undefined,
        ownProductsPreview: {cacheState: CacheState.Unknown, object: undefined},
        compareProductsKey: undefined,
        compareProductsPreview: {cacheState: CacheState.Unknown, object: undefined},
    }
};

const cacheReducer = (state: IAppCacheState = cacheInitialState,
                      action: ResponseCategoryStructureAction
                          | UserLoadSuccessAction
                          | CacheSaveSearchFoundAction
                          | CacheSaveSearchLoadingAction
                          | CacheSaveSearchNotFoundAction
                          | CacheSaveProductFoundAction
                          | CacheSaveProductNotFoundAction
                          | CacheSaveSearchNotRespondAction
                          | CacheSaveProductLoadingAction
                          | ProductPreviewLoadSuccessAction): IAppCacheState => {
    switch (action.type) {

        case RESPONSE_CATEGORY_STRUCTURE_SUCCESS: {
            const dataAction = action as ResponseCategoryStructureAction;
            const categoryId = dataAction.categoryId ? dataAction.categoryId : 0
            const structureKey = getCategoryStructureCacheKey(categoryId, dataAction.lang)

            return {
                ...state,

                categoryTree: {
                    ...state.categoryTree,
                    [structureKey]: {
                        cacheState: CacheState.Cache,
                        object: {
                            categoryId: categoryId,
                            name: "",
                            isCollection: false,
                            children: dataAction.children ? dataAction.children : []
                        }
                    }
                },
            };
        }

        case RESPONSE_CATEGORY_STRUCTURE_NOT_FOUND: {
            const dataAction = action as ResponseCategoryStructureAction;
            const categoryId = dataAction.categoryId ? dataAction.categoryId : 0
            const structureKey = getCategoryStructureCacheKey(categoryId, dataAction.lang)

            return {
                ...state,

                categoryTree: {
                    ...state.categoryTree,
                    [structureKey]: {
                        cacheState: CacheState.NotFound,
                        object: undefined
                    }
                }
            };
        }

        case LOAD_USER_SUCCESS: {
            const successAction = action as UserLoadSuccessAction;
            return {
                ...state,

                users: {
                    ...state.users,
                    [successAction.user.id]: {
                        cacheState: CacheState.Cache,
                        object: successAction.user
                    }
                }
            }
        }

        case CACHE_SAVE_SEARCH_FOUND: {
            // TODO: make a limit size of cache

            const successAction = action as CacheSaveSearchFoundAction;
            const cacheKey = getSearchCacheKey(successAction.searchKey, successAction.options.lang, successAction.options.debug)
            const categoryCacheKey = successAction.category
                ? getCategoryCacheKey(successAction.category.id, successAction.options.lang, successAction.options.debug)
                : undefined

            return {
                ...state,
                category: (
                    categoryCacheKey && successAction.category
                        ? {
                            ...state.category,
                            [categoryCacheKey]: {
                                cacheState: CacheState.Cache,
                                object: successAction.category
                            }
                        }
                        : {...state.category}
                ),
                search: {
                    ...state.search,
                    [cacheKey]: {
                        cacheState: CacheState.Cache,
                        object: {
                            items: successAction.list,
                            topRankProducts: successAction.topRankProducts,
                            uniqueNamePathRelatedCategories: successAction.uniqueNamePathRelatedCategories,
                            filters: successAction.filters,
                            searchQuery: successAction.searchParams,
                            responseDetails: successAction.responseDetails,
                        }
                    }
                },
            }
        }

        case CACHE_SAVE_SEARCH_NOT_FOUND: {
            const responseAction = action as CacheSaveSearchNotFoundAction;
            const cacheKey = getSearchCacheKey(responseAction.searchKey, responseAction.options.lang, responseAction.options.debug)

            if (state.search[cacheKey] == undefined || (state.search[cacheKey] && state.search[cacheKey].cacheState != CacheState.Cache)) {
                return {
                    ...state,
                    search: {
                        ...state.search,
                        [cacheKey]: {
                            cacheState: CacheState.NotFound,
                            object: undefined
                        }
                    }
                }
            }
            return state;
        }

        case CACHE_SAVE_SEARCH_NOT_RESPOND: {
            const responseAction = action as CacheSaveSearchNotRespondAction;
            const cacheKey = getSearchCacheKey(responseAction.searchKey, responseAction.options.lang, responseAction.options.debug)

            if (state.search[cacheKey] == undefined || (state.search[cacheKey] && state.search[cacheKey].cacheState != CacheState.Cache)) {
                return {
                    ...state,
                    search: {
                        ...state.search,
                        [cacheKey]: {
                            cacheState: CacheState.ServerNotResponse,
                            object: undefined
                        }
                    }
                }
            }
            return state;
        }

        case CACHE_SAVE_SEARCH_LOADING: {
            const responseAction = action as CacheSaveSearchLoadingAction;
            const cacheKey = getSearchCacheKey(responseAction.searchKey, responseAction.options.lang, responseAction.options.debug)

            if (state.search[cacheKey] == undefined
                || (state.search[cacheKey] && state.search[cacheKey].cacheState != CacheState.Cache && state.search[cacheKey].cacheState != CacheState.Loading)) {
                return {
                    ...state,
                    search: {
                        ...state.search,
                        [cacheKey]: {
                            cacheState: CacheState.Loading,
                            object: undefined
                        }
                    }
                }
            }
            return state;
        }

        case CACHE_SAVE_PRODUCT_FOUND: {
            const response = action as CacheSaveProductFoundAction;
            const categoryCacheKey = response.category
                ? getCategoryCacheKey(response.category.id, response.options.lang, response.options.debug)
                : ""

            const productIdKey = getProductCacheKey(response.product.id, response.options.lang, response.options.debug)
            const productGtinIdKey = response.product.gtinId
                ? getProductCacheKey(response.product.gtinId, response.options.lang, response.options.debug)
                : undefined

            const productData: IProductCache = {
                product: response.product,
                productDetails: response.data.variants || response.data.debugData
                    ? {
                        cacheState: CacheState.Cache,
                        object: {
                            variants: response.data.variants,
                            debugData: response.data.debugData,
                        }
                    }
                    : {
                        cacheState: CacheState.Unknown,
                        object: undefined
                    }
            }

            return {
                ...state,

                category: response.category
                    ? {
                        ...state.category,
                        [categoryCacheKey]: {
                            cacheState: CacheState.Cache,
                            object: response.category
                        }
                    }
                    : {...state.category},

                product: {
                    ...state.product,
                    ...(!productGtinIdKey ? {}
                        : {
                            [productGtinIdKey]: {
                                cacheState: CacheState.Cache,
                                object: productData
                            },
                        }),
                    [productIdKey]: {
                        cacheState: CacheState.Cache,
                        object: productData
                    },
                }
            }
        }

        case CACHE_SAVE_PRODUCT_NOT_FOUND: {
            const response = action as CacheSaveProductNotFoundAction;

            let additionalProductData: {
                [productKey: string]: ICacheObject<IProductCache>
            } = {};

            response.productIds.forEach((productId) => {
                const productKey = getProductCacheKey(productId, response.options.lang, response.options.debug)
                if (state.product[productKey] == undefined
                    || (state.product[productKey].cacheState != CacheState.Cache && state.product[productKey].cacheState != CacheState.Loading)) {
                    additionalProductData[productKey] = {
                        cacheState: CacheState.NotFound,
                        object: undefined
                    }
                }
            });

            return {
                ...state,
                product: {
                    ...state.product,
                    ...additionalProductData
                }
            }
        }

        case CACHE_SAVE_PRODUCT_LOADING: {
            const response = action as CacheSaveProductLoadingAction;

            let additionalProductData: {
                [productKey: string]: ICacheObject<IProductCache>
            } = {};

            response.productIds.forEach((productId) => {
                const productKey = getProductCacheKey(productId, response.options.lang, response.options.debug)
                if (state.product[productKey] == undefined
                    || (state.product[productKey].cacheState != CacheState.Cache && state.product[productKey].cacheState != CacheState.Loading)) {
                    additionalProductData[productKey] = {
                        cacheState: CacheState.Loading,
                        object: undefined
                    }
                }
            });

            return {
                ...state,
                product: {
                    ...state.product,
                    ...additionalProductData
                }
            }
        }
        case LOAD_PRODUCT_LIKE_PREVIEW_SUCCESS: {
            const successAction = action as ProductPreviewLoadSuccessAction;
            return {
                ...state,
                personalProducts: {
                    ...state.personalProducts,
                    likeProductsKey: successAction.productsKey,
                    likeProductsPreview: {
                        cacheState: CacheState.Cache,
                        object: successAction.products
                    }
                }
            }
        }
        case LOAD_PRODUCT_OWN_PREVIEW_SUCCESS: {
            const successOwnAction = action as ProductPreviewLoadSuccessAction;
            return {
                ...state,
                personalProducts: {
                    ...state.personalProducts,
                    ownProductsKey: successOwnAction.productsKey,
                    ownProductsPreview: {
                        cacheState: CacheState.Cache,
                        object: successOwnAction.products
                    }
                }
            }
        }
        case LOAD_PRODUCT_COMPARE_PREVIEW_SUCCESS: {
            const successOwnAction = action as ProductPreviewLoadSuccessAction;
            return {
                ...state,
                personalProducts: {
                    ...state.personalProducts,
                    compareProductsKey: successOwnAction.productsKey,
                    compareProductsPreview: {
                        cacheState: CacheState.Cache,
                        object: successOwnAction.products
                    }
                }
            }
        }
        case LOAD_PRODUCT_LIKE_PREVIEW_FAILED: {
            const successAction = action as ProductPreviewLoadFailedAction;
            return {
                ...state,
                personalProducts: {
                    ...state.personalProducts,
                    likeProductsKey: successAction.productsKey,
                    likeProductsPreview: {
                        cacheState: CacheState.NotFound,
                        object: undefined
                    }
                }
            }
        }
        case LOAD_PRODUCT_OWN_PREVIEW_FAILED: {
            const successOwnAction = action as ProductPreviewLoadFailedAction;
            return {
                ...state,
                personalProducts: {
                    ...state.personalProducts,
                    ownProductsKey: successOwnAction.productsKey,
                    ownProductsPreview: {
                        cacheState: CacheState.NotFound,
                        object: undefined
                    }
                }
            }
        }
        case LOAD_PRODUCT_COMPARE_PREVIEW_FAILED: {
            const successOwnAction = action as ProductPreviewLoadFailedAction;
            return {
                ...state,
                personalProducts: {
                    ...state.personalProducts,
                    compareProductsKey: successOwnAction.productsKey,
                    compareProductsPreview: {
                        cacheState: CacheState.NotFound,
                        object: undefined
                    }
                }
            }
        }
        case LOAD_PRODUCT_LIKE_PREVIEW_LOADING: {
            const successAction = action as ProductPreviewLoadFailedAction;
            return {
                ...state,
                personalProducts: {
                    ...state.personalProducts,
                    likeProductsKey: successAction.productsKey,
                    likeProductsPreview: {
                        cacheState: CacheState.Loading,
                        object: undefined
                    }
                }
            }
        }
        case LOAD_PRODUCT_OWN_PREVIEW_LOADING: {
            const successOwnAction = action as ProductPreviewLoadFailedAction;
            return {
                ...state,
                personalProducts: {
                    ...state.personalProducts,
                    ownProductsKey: successOwnAction.productsKey,
                    ownProductsPreview: {
                        cacheState: CacheState.Loading,
                        object: undefined
                    }
                }
            }
        }
        case LOAD_PRODUCT_COMPARE_PREVIEW_LOADING: {
            const successOwnAction = action as ProductPreviewLoadFailedAction;
            return {
                ...state,
                personalProducts: {
                    ...state.personalProducts,
                    compareProductsKey: successOwnAction.productsKey,
                    compareProductsPreview: {
                        cacheState: CacheState.Loading,
                        object: undefined
                    }
                }
            }
        }
    }

    return state;
}

export default cacheReducer;