import {getDefaultLang} from "./Action/interactive";
import {getProductCacheKey} from "./Action/cache.product";
import {emptyUrlParams, getPageParamsFromSearchUrl} from "../Helpers/SearchHelper";
import {SearchRouteContextType} from "./Context/SearchRouteContext";
import {parseProductUrl} from "../Helpers/UrlFormatter.Product";
import {parseUrlToSearchParams} from "../Helpers/UrlFormatter.Search";

function getRoute(path: string) {
    const searchParams = parseUrlToSearchParams(path, '')     // bad hack, but works for now
    if (!emptyUrlParams(searchParams.bridgeParams)) return 'search'

    return path.split('/')[path.indexOf('/') === 0 ? 1 : 0]
}

export function isCategoryPanelAvailable(state: IAppState, locationPath: string) {
    return locationPath === '/' || getRoute(locationPath) === 'search';
}

export function isFilterPanelAvailable(state: IAppState, locationPath: string) {
    return getRoute(locationPath) === 'search';
}

export function isOrderDropdownAvailable(state: IAppState, locationPath: string) {
    return getRoute(locationPath) === 'search';
}

export function isHeaderSearchVisible(state: IAppState, locationPath: string) {
    return locationPath !== '/';
}

export function isSuspendCookie(state: IAppState, locationPath: string) {
    return getRoute(locationPath) === 'policy';
}

export function getCompareCategoryId(state: IAppState, locationPath: string, searchContext: SearchRouteContextType | undefined) {
    const route = getRoute(locationPath);

    if (route === 'search') {
        const searchData = selectSearchCache(state, searchContext?.cacheKey);
        return searchData?.object?.searchQuery?.categoryId
    }

    if (route === "product") {
        const [productId, actualFormat] = parseProductUrl(locationPath, "")
        const productCacheKey = getProductCacheKey(productId  || 0,
            state.config.lang ? state.config.lang : getDefaultLang(),
            state.config.debug);
        const product = state.cache.product[productCacheKey]?.object?.product
        return product?.category_id;
    }

    return undefined;
}

// ---

export function getCategoryCacheKey(categoryId: number, lang: string, debug: boolean): string {
    return `${categoryId}.${lang}.${debug ? 1 : 0}`
}

//
// state selectors
//

export function selectSearchCache(state: IAppState, searchKey: string | undefined) {
    return searchKey && state.cache.search[searchKey] ? state.cache.search[searchKey] : undefined
}

export function selectSearchCacheState(state: IAppState, searchKey: string | undefined) {
    return searchKey && state.cache.search[searchKey] ? state.cache.search[searchKey].cacheState : undefined
}

export function selectSearchRequestOptions(state: IAppState): IApiSearchRequestPostParams {
    return {
        lang: state.config.lang ? state.config.lang : getDefaultLang(),
        debug: state.config.debug,
        _logPageToken: state.session.tracking.pageToken || null
    }
}

export function selectProductCacheState(state: IAppState, productKey: string | undefined) {
    if (productKey && state.cache.product[productKey]) {
        if (state.cache.product[productKey].cacheState == CacheState.Cache) {
            const productData = state.cache.product[productKey].object;
            if (productData) {
                return productData.productDetails ? productData.productDetails.cacheState : undefined;
            }
        }

        return state.cache.product[productKey].cacheState;
    }
    return undefined;
}

export function selectProductRequestOptions(state: IAppState): IApiProductRequestPostParams {
    return {
        lang: state.config.lang ? state.config.lang : getDefaultLang(),
        debug: state.config.debug,
        _logPageToken: state.session.tracking.pageToken || null
    }
}

export function selectShophunterProgress(state: IAppState) {
    return state.session.shophunter
}