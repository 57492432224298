var productImageUrl: string | undefined = undefined            // cdn
export const setProductImageBaseUrl = (url: string | undefined) => productImageUrl = url

export const getImageUrl = function (imageId: number | undefined, size: string) {
    if (imageId) return productImageUrl + '/' + imageId + size.toUpperCase() + '.jpg';
    return '/resources/no-img.png';
}

var domainImageUrl: string | undefined = undefined            // thumbnails
export const setDomainImageBaseUrl = (url: string | undefined) => domainImageUrl = url

export const getDomainImageUrl = function (imageId: number | undefined, size: string) {
    if (imageId) return domainImageUrl + '/D' + imageId + size.toUpperCase() + '.jpg';
    return '/resources/no-img.png';
}

export const getCountryFlagImageUrl = (code: string | undefined) => (
    typeof code === 'string'
        ? `/resources/flags/country/flag_${code.toLowerCase()}.png`
        : '/resources/no-img.png'
)

var avatarImageUrl: string | undefined = undefined            // avatar
export const setAvatarImageUrl = (url: string | undefined) => avatarImageUrl = url

export const getAvatarImageUrl = (imageId: number | undefined, size = 'm') => (
    imageId && avatarImageUrl
        ? `${avatarImageUrl}/${imageId}${size.toLowerCase()}.jpg`
        : '/resources/no-profile-img.png'
)

var boardImageUrl: string | undefined = undefined            // avatar
export const setBoardImageUrl = (url: string | undefined) => boardImageUrl = url

export const getBoardImageUrl = (imageId: number | undefined) => (
    imageId && boardImageUrl
        ? `${boardImageUrl}/profile_${imageId}.jpg`
        : '/resources/no-img.png'
)

// ---

export const translateProductImageType = (type: keyof typeof VERIFICATION_IMAGE_STATUS) => {
    switch (type) {
        case VERIFICATION_IMAGE_STATUS.MAIN:
            return 'MAIN';
        case VERIFICATION_IMAGE_STATUS.UNRELATED:
            return 'PP.UNRL';
        case VERIFICATION_IMAGE_STATUS.ACCESSORY:
            return 'PP.ACC';
        case VERIFICATION_IMAGE_STATUS.OK_IMAGE:
            return 'PP.OK';
        default:
            return 'UNK:' + type;
    }
}

export const translateImageType = (type: keyof typeof VERIFICATION_IMAGE_TYPE) => {
    switch (type) {
        case VERIFICATION_IMAGE_TYPE.ISOLATED:
            return 'I';
        case VERIFICATION_IMAGE_TYPE.GRADIENT:
            return 'G';
        case VERIFICATION_IMAGE_TYPE.LIGHT_GRADIENT :
            return 'L-G';
        case VERIFICATION_IMAGE_TYPE.SOLID :
            return 'S';
        case VERIFICATION_IMAGE_TYPE.HOMOGEN :
            return 'H';
        case VERIFICATION_IMAGE_TYPE.DRAWING :
            return 'DRW';
        case VERIFICATION_IMAGE_TYPE.BAD_SOFT_PIXELS :
            return 'BAD.S/F';
        case VERIFICATION_IMAGE_TYPE.BAD_UNCROPPED :
            return 'BAD.U';
        default:
            return 'UNK:' + type;
    }
}

export const translateContentImageType = (type: keyof typeof VERIFICATION_IMAGE_CONTENT_TYPE) => {
    switch (type) {
        case VERIFICATION_IMAGE_CONTENT_TYPE.ICON_NO_IMAGE:
            return 'NO';
        case VERIFICATION_IMAGE_CONTENT_TYPE.ICON_CAMPAIGN:
            return 'CMPG';
        case VERIFICATION_IMAGE_CONTENT_TYPE.ICON_SYMBOL_OR_FUNCTION:
            return 'SYM';
        case VERIFICATION_IMAGE_CONTENT_TYPE.ICON_WEBSITE_DESIGN:
            return 'WEB';
        case VERIFICATION_IMAGE_CONTENT_TYPE.BAD_UNRELATED:
            return 'UNRL';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_CLOSEUP:
            return 'P.CLO';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_SIDE:
            return 'P.SID';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_TOP:
            return 'P.TOP';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_BOTTOM:
            return 'P.BOT';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_BACK:
            return 'P.BACK';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_MULTIPLE:
            return 'P.MUL';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_ENVIRONMENT:
            return 'P.ENV';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_FRONT:
            return 'P.FRO';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_OPEN:
            return 'P.OPN';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_CLOSED:
            return 'P.FLD';     // calling 'Folded / Closed', so 'fld'. issue #38051
        case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_ONE:
            return 'PRD';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_IN_USAGE:
            return 'PR.USE';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_MULTIPLE_VARIANTS:
            return 'PR.VAR';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_MULTIPLE_SAME:
            return 'PR.SAM';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_ONE_ACCESSORY_OR_PART:
            return 'PR.A/P';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_SET_OR_COLLECTION:
            return 'SET';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_SCREENSHOT:
            return 'SCR';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_AND_COVER:
            return 'PR+COV';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_COVER_ONLY:
            return 'PR.COV';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_AND_PRODUCT:
            return 'PK+PR';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_UNIQUE:
            return 'PKG';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_BACK:
            return 'PKG.BK';
        case VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_GENERIC:
            return 'PKG.GN';
        case VERIFICATION_IMAGE_CONTENT_TYPE.BOOK_MEDIA_COVER:
            return 'BM.C';
        case VERIFICATION_IMAGE_CONTENT_TYPE.BOOK_MEDIA_COVER_BACK:
            return 'BM.BK';
        case VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_BLUEPRINT:
            return 'DR.BL';
        case VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_3D:
            return 'DR.3D';
        case VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_ILLUSTRATION:
            return 'DR.ILL';
        case VERIFICATION_IMAGE_CONTENT_TYPE.TEXT:
            return 'TXT';
        case VERIFICATION_IMAGE_CONTENT_TYPE.COLLAGE_TEXT_IMAGE:
            return 'COLL';
        case VERIFICATION_IMAGE_CONTENT_TYPE.CHART_GRAPH:
            return 'GRPH';
        case VERIFICATION_IMAGE_CONTENT_TYPE.CHART_SIZE:
            return 'SIZE';
        case VERIFICATION_IMAGE_CONTENT_TYPE.CHART_ENERGY_CERTIFICATE:
            return 'E.CRT';
        case VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_PALETTE_COLOR_MATERIAL:
            return 'CM.MUL';
        case VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_ONE_MATERIAL_COLOR:
            return 'CM.1';
        case VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_ONE_PATTERN:
            return 'PATT';
        case VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_BRAND:
            return 'L.BRN';
        case VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_VENDOR:
            return 'L.VEN';
        case VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_PRODUCT:
            return 'L.PRD';
        case VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_TECH:
            return 'L.TEC';
        case VERIFICATION_IMAGE_CONTENT_TYPE.MULTIPLE_LOGOS:
            return 'L.MUL';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FOCUS_BOTTOM:
            return 'H.BOT';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FOCUS_TOP:
            return 'H.TOP';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_CLOSEUP:
            return 'H.CLO';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FULL:
            return 'H.FUL';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_SIDE:
            return 'H.SID';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_BACK:
            return 'H.BAK';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_HEAD:
            return 'H.HED';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_HANDS:
            return 'H.HND';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FEET:
            return 'H.FET';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_SITTING:
            return 'H.SIT';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_MULTI_PERSPECTIVE:
            return 'H.M-P';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_MULTI_VARIANTS:
            return 'H.M-V';
        case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_ENVIRONMENT:
            return 'H.ENV';
        default:
            return 'UNK:' + type;
    }
}

export const translateImageWatermarkType = (type: keyof typeof VERIFICATION_IMAGE_WATERMARK) => {
    switch (type) {
        case VERIFICATION_IMAGE_WATERMARK.WATERMARK_TRUE:
            return 'W';
        case VERIFICATION_IMAGE_WATERMARK.WATERMARK_FALSE:
            return '!W';
        default:
            return 'UNK:' + type;
    }
}
export const translateImageOverlayType = (type: keyof typeof VERIFICATION_IMAGE_OVERLAY) => {
    switch (type) {
        case VERIFICATION_IMAGE_OVERLAY.OVERLAY_TEXT:
            return 'O.T';
        case VERIFICATION_IMAGE_OVERLAY.OVERLAY_FALSE:
            return '!O';
        case VERIFICATION_IMAGE_OVERLAY.OVERLAY_LOGO:
            return 'O.L';
        case VERIFICATION_IMAGE_OVERLAY.OVERLAY_SYMBOL:
            return 'O.S';
        case VERIFICATION_IMAGE_OVERLAY.OVERLAY_CAMPAIGN:
            return 'O.CP';
        case VERIFICATION_IMAGE_OVERLAY.OVERLAY_CLOSEUP:
            return 'O.CL';
        case VERIFICATION_IMAGE_OVERLAY.OVERLAY_GRAPHICS:
            return 'O.G';
        case VERIFICATION_IMAGE_OVERLAY.OVERLAY_MEASUREMENT:
            return 'O.M';
        default:
            return 'UNK:' + type;
    }
}
